export const lang = {
	//公共
	common: {
		//语言包名称
		name: '越语',
	},
	'所有商品分类': 'Mọi loại hàng hóa',
	'全国': 'cả nước',
	'切换城市': 'Chuyển thành',
	'您好，请登录 注册': 'Xin chào, đăng nhập ghi',
	'退出': 'kí vào đây',
	'免费注册': 'Đăng ký miễn phí',
	'我的订单': 'Lệnh của tôi',
	'我的足迹': 'Dấu vết của tôi',
	'我的关注': 'Chú ý.',
	'帮助中心': 'Trung tâm Trợ giúp',
	'公告': 'Ghi',
	'手机商城': 'Phân tâm Description',
	'商品': 'hàng',
	'店铺': 'cửa',
	'搜索': 'tìm',
	'热门搜索': 'Xoá tìm kiếm',
	'我的购物车': 'Xe của tôi',
	'最新加入的商品': 'Mới được thêm',
	'删除': 'xóa',
	'共': 'chung',
	'种商品，总金额': 'Hàng, tổng số',
	'去购物车': 'Tới xe hàng đi',
	'您的购物车是空的，赶快去逛逛，挑选商品吧！': 'Xe hàng của bạn trống rỗng. Đi mua sắm và chọn hàng nhanh lên!',
	'您买的东西太少了，这里都空空的，快去挑选合适的商品吧！': 'Ông đã mua quá ít đồ, ở đây trống không, hãy chọn hàng đi!',
	'成长值': 'Giá trị tăng',
	'未登录': 'Chưa đăng nhập',
	'优惠券': 'LãoNgọt:',
	'积分': 'Name',
	'余额': 'dư',
	'店铺关注': 'Lưu ý',
	'商品关注': 'Tài sản',
	'待付款': 'Tiền trước',
	'待发货': 'Được vận chuyển',
	'待收货': 'Hàng hóa cần nhận',
	'待评价': 'Để đánh giá',
	'退款/售后': 'Từ chối sau bán',
	'查看详情': 'Xem chi tiết',
	'会员中心': 'Trung tâm hội viên',
	'欢迎页': 'Trang Welcome',
	'个人信息': 'Thông tin cá nhân',
	'账户安全': 'Bảo mật tài khoản',
	'收货地址': 'Địa chỉ nhận',
	'交易中心': 'Trung tâm thương mại',
	'核销台': 'Văn phòng kiểm tra',
	'核销记录': 'Ghi âm lại',
	'账户中心': 'Trung tâm tài khoản',
	'账户余额': 'Tài khoản',
	'提现记录': 'Thu hồi',
	'我的优惠券': 'Phiếu của tôi',
	'我的积分': 'Điểm của tôi',
	'账户列表': 'Danh sách tài khoản',
	'基本信息': 'Thông tin quan trọng',
	'账号': 'Số tài khoản',
	'去绑定': 'Name',
	'保存': 'bảo:',
	'邮箱': 'Hộp thư',
	'手机号': 'Số di động',
	'昵称': 'Biệt danh?',
	'头像照片': 'Ảnh Avatar',
	'头像预览': 'Xem thử Avatar',
	'头像最佳默认尺寸为120x120像素。': 'The best default size of the Avatar is 120x12x120 pixel.',
	'完善个人信息资料，上传头像图片有助于您结识更多的朋友。': 'Hãy hoàn thiện thông tin cá nhân của bạn và tải lên ảnh Avatar để giúp bạn hiểu nhau hơn.',
	'登录密码': 'Nhập mật khẩu',
	'互联网账号存在被盗风险，建议您定期更改密码以保护账户安全': 'Có nguy cơ ăn cắp tài khoản internet của bạn. Bạn nên thay đổi mật khẩu thường xuyên để bảo vệ an ninh của tài khoản.',
	'修改': 'Chỉnh:',
	'邮箱绑定': 'Hộp thư',
	'验证后，可用于快速找回登录密码及支付密码': 'Sau khi kiểm tra, nó có thể được dùng để lấy mật khẩu đăng nhập và mật khẩu thanh to án.',
	'手机验证': 'Kiểm tra điện thoại',
	'验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒': 'Sau khi kiểm tra, nó có thể được dùng để lấy mật khẩu đăng nhập và mật khẩu trả tiền và nhận hồi ký của các thay đổi tài khoản.',
	'原密码': 'Mật khẩu gốc',
	'当前密码': 'Mở mật khẩu',
	'取消': 'hủy',
	'新密码': 'Mật khẩu mới',
	'确认密码': 'Xác nhận mật khẩu',
	'请确认新密码': 'Xin hãy xác nhận mật khẩu mới.',
	'验证码': 'Mật khẩu',
	'请输入验证码': 'Hãy nhập mật mã kiểm tra.',
	'动态码': 'Mã động',
	'请输入动态码': 'Hãy nhập mã động',
	'点击“获取动态码”，将会向您已绑定的手机号': 'Nhấn vào "Lấy mã động" và sẽ hiển thị số điện thoại di động bị ràng buộc',
	'发送验证码': 'Gửi mã kiểm tra',
	'绑定邮箱': 'Hộp thư dán',
	'当前邮箱': 'Hộp thư',
	'请输入邮箱': 'Hãy nhập địa chỉ email',
	'绑定手机号': 'Số điện thoại đính',
	'当前手机号': 'Điện thoại di động',
	'请输入手机号': 'Xin hãy nhập số điện thoại.',
	'绑定支付密码': 'Mã số giao dịch',
	'请输入6位支付密码，建议不要使用重复或连续数字': 'Hãy nhập mật khẩu số sáu số. Khuyên bạn không nên sử dụng các con số lặp lại hay liên tiếp.',
	'验证码已发送至': 'Mật khẩu được gửi đến',
	'添加收货地址': 'Thêm địa chỉ chuyển',
	'默认': 'mặc định',
	'编辑': 'soạn thảo',
	'设为默认': 'Đặt là mặc định',
	'编辑收货地址': 'Sửa địa chỉ chuyển',
	'姓名': 'tên đầy đủ',
	'是': 'Phải.',
	'否': 'không',
	'收货人姓名': 'Tên người nhận',
	'收货人手机号': 'Số điện thoại người nhận',
	'手机': 'di động',
	'电话': 'Điện',
	'地址': 'địa chỉ',
	'请选择省': 'Hãy chọn Tỉnh',
	'请选择市': 'Hãy chọn một thành phố',
	'详细地址': 'Địa chỉ chi tiết',
	'是否默认': 'Mặc',
	'定位到小区、街道、写字楼': 'Định vị cộng đồng, đường phố và văn phòng',
	'手机号不能为空': 'Số điện thoại di động không thể rỗng',
	'请输入正确的手机号': 'Hãy nhập số điện thoại di động đúng',
	'请选择区/县': 'Xin hãy chọn quận',
	'请输入收货人姓名': 'Hãy ghi tên người nhận.',
	'宝贝': 'baby',
	'上一页': 'trang trước',
	'下一页': 'trang kế',
	'您还没有关注店铺哦！': 'Anh vẫn chưa chú ý đến cửa hàng!',
	'商品描述': 'Mô tả',
	'卖家服务': 'Dịch vụ bán',
	'发货速度': 'Tốc độ giao hàng',
	'联系电话': 'Số liên lạc',
	'自营': 'tự chủ',
	'收货人固定电话（选填）': 'Số điện thoại cố định của người nhận',
	'取消关注成功': 'Xoá tập trung thành',
	'您还没有关注商品哦': 'Anh vẫn chưa chú ý đến sản phẩm.',
	'我的足迹': 'Dấu vết của tôi',
	'暂时没有足迹~': '♪ Không có dấu chân nào trong thời gian này ♪',
	'全部订单': 'Mọi mệnh lệnh',
	'商品信息': 'Thông tin Tài sản',
	'单价': 'Giá đơn',
	'服务': 'dịch',
	'数量': 'Số',
	'实付款': 'Thanh toán thực',
	'订单状态': 'Trật tự',
	'操作': 'Chạy',
	'订单号': 'Số thứ tự',
	'订单详情': 'Đơn vị trật tự',
	'详细信息': 'chi tiết',
	'转账确认中': 'Xác nhận chuyển nhượng đang chạy',
	'评价': 'đánh',
	'追评': 'Xem',
	'转账详情': 'Chi tiết truyền:',
	'暂无相关订单': 'Không có lệnh',
	'转账图片': 'Truyền ảnh truyền',
	'提示：如果需要更改转账截图，您可以直接选择支付-转账支付，从新上传支付凭证。': 'Gợi ý: nếu bạn cần thay đổi màn hình chuyển nhượng, bạn có thể chọn trực tiếp thanh to án tiền dịch chuyển và tải lên voucher lần nữa.',
	'退款': 'tiền',
	'退货': 'thu nhập',
	'金额': 'bao nhiêu tiền',
	'退款类型': 'Kiểu bồi thường',
	'退款无需退货': 'Không hồi đáp',
	'退货退款': 'Trả lại tiền',
	'退款金额': 'bù đắp',
	'退款原因': 'Lý do bồi thường',
	'请选择': 'Hãy chọn',
	'退款说明': 'Hướng dẫn phục hồi',
	'请输入退款说明（选填）': 'Hãy nhập vào mô tả hoàn toàn (tùy chọn)',
	'提交': 'Gởi',
	'退款状态': 'Bộ lọc',
	'退款编号': 'Số lần thanh toán',
	'退款成功': 'Thành công',
	'退款详情': 'Địa chỉ nhà kho',
	'退款中': 'Đang hồi phục',
	'提示': 'Mẹo',
	'确认撤销': 'Xác nhận hủy',
	'暂不撤销': 'Lúc này thì không.',
	'撤销成功': 'Kết thúc',
	'撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请': 'Sau khi hủy bỏ, ứng dụng sẽ được đóng lại. Nếu vẫn có vấn đề trong việc tiếp theo, ứng dụng có thể được khởi động lại.',
	'关注商品': 'Tiêu điểm hàng hóa',
	'联系客服': 'Liên lạc với khách hàng',
	'限时折扣': 'Giảm giờ',
	'销售价': 'Giá bán',
	'原价': 'giá gốc',
	'分': 'chi',
	'市场价': 'giá trị chợ',
	'取消关注': 'Hủy chú ý',
	'关注店铺': 'Hãy chú ý đến cửa hàng',
	'店铺评分': 'Lưu ghi chú',
	'进店逛逛': 'Đi mua sắm',
	'累计评价': 'Kiểm tra tổng hợp',
	'累计销量': 'Bán tổng hợp',
	'视频': 'video',
	'图片': 'ảnh',
	'运费': 'hàng',
	'快递免邮': 'Bưu điện Express miễn phí',
	'快递不免邮': 'Nhận thư không phải miễn phí',
	'折': 'gãy',
	'满减': 'Giảm hết',
	'满': 'đầy',
	'减': 'giảm',
	'配送至': 'Chuyển cho',
	'请选择配送地址': 'Hãy chọn địa chỉ chuyển hàng',
	'由': 'từ',
	'发货并提供售后服务': 'Giao hàng và cung cấp dịch vụ sau bán.',
	'库存': 'cổ phiếu',
	'由官方旗舰店发货并提供售后服务': 'Các cửa hàng lớn chính thức vận chuyển hàng hóa và cung cấp dịch vụ bán hàng.',
	'库存不足': 'Không có số liệu',
	'立即购买': 'Mua ngay đi.',
	'加入购物车': 'thêm vào giỏ',
	'手机购买': 'Giá điện thoại',
	'组合套餐价': 'Giá gói phụ',
	'商品详情': 'chi tiết',
	'商品属性': 'thuộc tính',
	'商品评价': 'Đánh giá hàng hóa',
	'全部评价': 'Mọi đánh giá',
	'追加评价': 'Thêm đánh giá',
	'该商品暂无评价哦': 'Không có đánh giá nào về sản phẩm này',
	'售后保障': 'Sau bảo hiểm bán',
	'商品精选': 'Chọn chất lượng',
	'团购进行中': 'Đang mua nhóm',
	'团购价': 'Giá mua nhóm',
	'暂无正在进行团购的商品，去首页看看吧': 'Không có hàng hóa dưới quyền mua nhóm. Đến trang chủ xem qua',
	'团购': 'Mua nhóm',
	'天': 'ngày',
	'小时': 'giờ',
	'分钟': 'Phút 1',
	'秒': 'giây',
	'起购': 'Bắt đầu mua',
	'立即抢购': 'Mua ngay đi.',
	'商家服务': 'Buôn bán',
	'保证服务': 'Dịch vụ bảo hiểm',
	'7天退换': 'Trả lại và trao đổi trong vòng bảy ngày',
	'正品保障': 'Bảo đảm thực sự',
	'两小时发货': 'Hai tiếng giao hàng.',
	'退货承诺': 'Giao dịch',
	'实体验证': 'Mở đích',
	'试用中心': 'Trung tâm thử',
	'消协保证': 'Bảo đảm môi trường',
	'暂无更多品牌,去首页看看吧': 'Không còn nhãn hiệu nào nữa. Đến trang chủ đi.',
	'没有找到您想要的商品。换个条件试试吧': 'Thứ anh muốn không được tìm thấy. Thử điều kiện khác',
	'搜索结果': 'tìm kiếm',
	'综合': 'toàn',
	'销量': 'Số lượng bán',
	'价格': 'Nguồn:',
	'包邮': 'lương thực',
	'最低价格': 'giá sàn',
	'最高价格': 'giá trần',
	'确定': 'xác định',
	'敬请期待': 'Sắp đến rồi.',
	'即将开始': 'bắt đầu trong một phút.',
	'抢购中': 'Đang vội mua',
	'已结束': 'Đã kết thúc',
	'秒杀价': 'Giá cả tăng',
	'暂无正在进行秒杀的商品，去首页看看吧': 'Không có hàng hóa trong lần giết thứ hai. Đến trang chủ xem thử.',
	'暂无商品分类': 'Không phân loại hàng hóa',
	'优惠券中心': 'Vào giữa',
	'省钱更多，领券更多': 'tiết kiệm tiền và kiếm thêm phiếu',
	'限时抢券': 'Thời gian hạn chế chứng khoán',
	'叠加使用': 'Dùng phủ',
	'种类多样': 'Số:',
	'店铺优惠券': 'Lưu phiếu',
	'使用店铺': 'Bật/ tắt',
	'无门槛优惠券': 'Không phiếu ngưỡng',
	'可使用': 'Mở',
	'暂无优惠券': 'Không phiếu',
	'去使用': 'Để dùng',
	'立即领取': 'Lấy ngay đi.',
	'有效期至': 'Hợp lệ đến',
	'领取之日起': 'Từ ngày phát hành',
	'日内有效': 'Hợp lệ trong ngày',
	'购物车': 'Xe bán hàng',
	'账号登录': 'Tên tài khoản',
	'请输入账号': 'Xin hãy nhập số tài khoản.',
	'请输入登录密码': 'Hãy nhập mật khẩu đăng nhập',
	'忘记密码': 'Quên mật khẩu',
	'登录': 'Ký vô.',
	'没有账号': 'Không tài khoản',
	'立即注册': 'Đăng ký ngay',
	'手机动态码登录': 'Mã động cơ sẵn sàng',
	'请输入短信动态码': 'Hãy nhập mã động SMS.',
	'七天自动登录': 'Đăng nhập tự động bảy ngày',
	'用户注册': 'Đăng ký người dùng',
	'请输入用户名': 'nhập một tên người dùng',
	'请输入密码': 'Nhập mật khẩu đi.',
	'阅读并同意': 'Đọc và đồng ý',
	'服务协议': 'Giao dịch',
	'注册': 'ghi',
	'已有账号': 'Tài khoản tồn tại',
	'立即登录': 'Đăng nhập ngay.',
	'手机动态码注册': 'Mã động cơ động',
	'已有账号，立即登录': 'Nếu anh có tài khoản, nhập vào ngay.',
	'订单列表': 'Danh sách lệnh 12',
	'转账支付': 'Thanh toán truyền',
	'需付款': 'Trả tiền yêu cầu',
	'订单信息': 'Thông tin trật tự',
	'订单类型': 'Kiểu trật tự',
	'订单编号': 'Số Thứ tự',
	'订单交易号': 'Số hiệu giao dịch',
	'配送方式': 'Chế độ phân phối',
	'创建时间': 'Đã tạo',
	'支付方式': 'Phương pháp thanh toán',
	'支付时间': 'Thời gian thanh toán',
	'店铺活动': 'Hoạt động lưu trữ',
	'买家留言': 'Tin nhắn người mua',
	'收货人': 'người nhận',
	'手机号码': 'Số điện thoại',
	'邮编': 'Mã khóa',
	'发票信息': 'Thông tin danh dự',
	'发票类型': 'Kiểu hóa đơn',
	'发票抬头类型': 'Thay đổi kiểu đầu trang',
	'发票抬头': 'Trang đầu',
	'发票内容': 'Thực đơn',
	'发票邮寄地址地址': 'Bưu điện danh dự',
	'纸质发票': 'Giấy bồi',
	'电子发票': 'Điện tử',
	'个人': 'cá nhân',
	'企业': 'kinh doanh',
	'发票接收邮箱': 'Bộ nhận chi phiếu',
	'小计': 'Trừ',
	'查看退款': 'Xem hoàn',
	'商品金额': 'Số sản xuất',
	'税费': 'Thuế',
	'订单调整': 'Chỉnh lệnh',
	'优惠': 'Giảm',
	'优惠券金额': 'Số phiếu',
	'平台优惠券金额': 'Tiền quỹ tảng',
	'使用余额': 'Dùng thăng bằng',
	'实付款': 'Thanh toán thực',
	'关闭时间': 'Hết giờ',
	'您确定要关闭该订单吗？': 'Anh có chắc muốn kết thúc việc này không?',
	'订单关闭成功': 'Sắp xếp đã đóng',
	'您确定已经收到货物了吗？': 'Anh có chắc là anh đã nhận được hàng không?',
	'订单收货成功': 'Đã nhận trật tự',
	'限时秒杀': 'Hạn chế thời gian chết thứ hai',
	'该商品已下架': 'Sản phẩm đã được gỡ ra khỏi giá sách.',
	'您的订单已提交成功，正在等待处理！': 'Lệnh của anh đã được gởi đi thành công và đang chờ xử lý 22!',
	'应付金额': 'Trả trước',
	'交易单号': 'Hành động',
	'订单内容': 'Nội dung trật tự',
	'订单金额': 'Số lượng',
	'商家未配置支付方式': 'Thương nhân chưa cấu hình phương pháp thanh toán',
	'确认支付': 'Xác nhận thanh toán',
	'立即支付': 'Thanh toán ngay',
	'银行开户名': 'Tên tài khoản ngân hàng',
	'银行账号': 'tài khoản ngân hàng',
	'开户支行': 'Thông tin mở đầu',
	'我的可用余额': 'Phần còn lại',
	'提现': 'Rút',
	'充值': 'Nạp',
	'可提现余额': 'Hủy bỏ',
	'不可提现余额': 'Số dư không rút',
	'来源': 'Nguồn',
	'详细说明': 'chi tiết',
	'时间': 'giờ',
	'描述相符': 'Mô tả khớp',
	'服务态度': 'Trình độ dịch vụ',
	'搜索 商品': 'Tìm kiếm sản phẩm',
	'本店推荐': 'Theo gợi ý của cửa hàng',
	'欢迎您': 'Chào.',
	'商城资讯': 'Thông tin Mall',
	'更多': 'thêm',
	'账户类型': 'Kiểu tài khoản',
	'提现金额': 'Số rút lui',
	'提现时间': 'Khoảng thời gian',
	'提现状态': 'Rút lui',
	'详情': 'chi tiết',
	'暂无提现记录': 'Không ghi nhận rút tiền mặt',
	'提现详情': 'Dữ liệu rút lui',
	'当前状态': 'hiện thời',
	'交易号': 'Số chuyển',
	'手续费': 'Phí dịch vụ',
	'申请时间': 'Thời gian ứng dụng',
	'审核时间': 'Thời gian kiểm tra',
	'银行名称': 'Tên ngân hàng',
	'收款账号': 'Số tài khoản bộ',
	'拒绝理由': 'Lý do từ chối',
	'转账方式名称': 'Phương pháp chuyển nhượng',
	'转账时间': 'Thời gian truyền',
	'平台优惠券': 'Công tắc',
	'已过期': 'Name',
	'未使用': 'không dùng',
	'已使用': 'Dùng',
	'可用': 'có',
	'中文': 'Trung',
	'英文': 'AnhName',
	'越语': 'Ngôn ngữ Yue',
	'日语': 'Nhật',
	'首页': 'trang chủ',
	'帮助': 'Cứu',
	'帮助列表': 'Danh sách trợ giúp',
	'帮助详情': 'Chi tiết trợ giúp',
	'公告列表': 'Danh sách Thông báo',
	'公告详情': 'Thông báo chi tiết',
	'最新公告': 'Tin mới nhất',
	'商城公告': 'Thông báo Mall',
	'登录': 'Ký vô.',
	'失效商品': 'Đồ vô danh',
	'件': 'mảnh',
	'失效': 'không hợp',
	'全选': 'Chọn tất cả',
	'清除失效宝贝': 'Xoá vết thương',
	'已选商品': 'Mục đã chọn',
	'合计（不含运费）': 'Tổng cộng (ngoài hàng)',
	'结算': 'bồi thường',
	'确定要删除该地址吗？': 'Bạn có chắc muốn xoá địa chỉ 12h không?',
	'更多收货地址': 'Địa chỉ chuyển hàng',
	'税收': 'thu nhập thuế',
	'请先添加收货地址': 'Hãy thêm địa chỉ tiếp nhận trước.',
	'商家未配置配送方式': 'Thương nhân chưa đặt phương pháp giao hàng',
	'需要发票': 'Chuẩn bị hóa đơn',
	'无需发票': 'Không cần hóa đơn',
	'请设置发票信息': 'Vui lòng đặt thông tin hóa đơn',
	'设置': 'thiết lập',
	'已选择优惠券，已优惠': 'Hàng đến chỗ đặt',
	'不使用优惠券': 'Không được dùng coupons.',
	'订单结算': 'Sắp xếp trật tự',
	'选择优惠券': 'Chọn phiếu',
	'名称': 'tên',
	'使用': 'dùng',
	'期限': 'Dài',
	'确认选择': 'Xác nhận chọn',
	'选择平台优惠券': 'Chọn phiếu nền',
	'任意金额可用': 'Bất cứ giá nào',
	'选择门店': 'Chọn kho',
	'营业时间': 'Giờ kinh doanh',
	'送达时间': 'Thời gian giao hàng',
	'选择时间': 'Chọn thời gian',
	'关闭': 'đóng',
	'满额包邮': 'Gói đầy đủ',
	'留言前建议先与商家协调一致': 'Tốt nhất là phối hợp với thương nhân trước khi để lại tin nhắn',
	'添加发票信息': 'Thêm thông tin hóa đơn',
	'请填写抬头名称': 'Xin hãy điền vào tựa đề',
	'请填写纳税人识别号': 'Xin hãy điền vào số của người đóng thuế',
	'请填写发票邮寄地址': 'Điền vào địa chỉ gửi qua hóa đơn',
	'请填写接收邮箱': 'Xin hãy điền vào email nhận',
	'地区': 'vùng',
	'邮编（字母或数字）': 'Mã khóa (chữ hay số)',
	'定位小区、街道、写字楼': 'Định vị cộng đồng, đường phố và văn phòng',
	'请输入您的手机号码': 'Hãy nhập số điện thoại di động',
	'购买虚拟类商品需填写您的手机号，以方便商家与您联系': 'Khi mua hàng ảo, bạn cần điền vào số điện thoại di động để giúp người thương nhân liên lạc với bạn.',
	'是否使用余额': 'Dùng thăng bằng',
	'不使用余额': 'Không dùng dư',
	'为了您的账户安全,请您先设置的支付密码': 'Để bảo mật tài khoản, hãy đặt mật khẩu thanh toán trước.',
	'可到"会员中心","账号安全","支付密码"中设置': 'Bạn có thể đặt nó trong "Trung tâm thành viên", "an ninh tài khoản" và "mật khẩu thanh toán"',
	'暂不设置': 'Chưa đặt tạm thời',
	'立即设置': 'Đặt ngay',
	'件起,享团购价': 'Thưởng thức giá mua hàng của nhóm',
	'发票': 'hóa',
	'领券中心': 'Trung tâm bộ sưu tập',
	'商城服务': 'dịch vụ mua sắm',
	'招商入驻': 'Giấy mời và dàn xếp',
	'商家中心': 'Trung tâm Thương mại',
	'门店管理': 'Quản lý kho',
	'更多商品': 'Thêm sản phẩm',
	'鲜花电商认证龙头': 'Chỉ huy Chứng nhận về e-thương Name',
	'16年品牌': 'Dấu hiệu 16 năm',
	'3小时全国送花': 'Truyền hoa quốc gia ba giờ.',
	'200%退赔承诺': '200)}cam kết hoàn toàn',
	'最近472658条评价': 'Thí nghiệm 4958 gần đây',
	'专区': 'Vùng đặc biệt',
	'已售': 'Bán',
	'热门资讯': 'Tin nóng',
	'关于我们': 'Về chúng ta',
	'联系方式': 'Thông tin liên lạc',
	'友链': 'chuỗi bạn bè',
	'收藏网站': 'Các website',
	'关注微信': 'Theo webLanguage',
	'下载APP': 'Duyệt ứng dụng',
	'订单查询': 'Hỏi trật tự',
	'客户服务': 'dịch vụ khách'



}
