export const lang = {
   //公共
   common: {
      //语言包名称
      name: '日语',
   },
   '所有商品分类': 'すべての商品の分类',
   '全国': '全国',
   '切换城市': '切替都市',
   '您好，请登录 注册': 'こんにちは、登录 登録',
   '退出': '退出',
   '免费注册': '无料登录',
   '我的足迹': '私の足迹',
   '我的关注': '私の関心',
   '帮助中心': 'ヘルプセンター',
   '公告': '公告',
   '手机商城': '携帯电话商城',
   '商品': '商品',
   '店铺': '店舗',
   '搜索': '検索',
   '热门搜索': '人気検索',
   '我的购物车': '私のショッピングカート',
   '最新加入的商品': '最新加入商品',
   '删除': '削除',
   '共': '共',
   '种商品，总金额': '商品、総额',
   '去购物车': 'ショッピングカート',
   '您的购物车是空的，赶快去逛逛，挑选商品吧！': 'あなたのショッピングカートは空いています。早くぶらぶらして、商品を选びましょう。',
   '您买的东西太少了，这里都空空的，快去挑选合适的商品吧！': 'お買い上げの品が少なすぎて、ここは空っぽなので、早く適当な商品を選びに行きましょう。',
   '成长值': '成長値',
   '未登录': '未登録',
   '优惠券': 'クーポン',
   '积分': 'ポイント',
   '余额': '残高',
   '店铺关注': '店舗注目',
   '商品关注': '商品注目',
   '我的订单': '私の注文',
   '待付款': '支払い待ち',
   '待收货': '未収品',
   '我的订单': '私の注文',
   '待发货': '出荷待ち',
   '待评价': '評価待ち',
   '退款/售后': '返金/アフターサービス',
   '查看详情': '詳細を見る',
   '会员中心': '会員センター',
   '个人信息': '個人情報',
   '账户安全': '口座安全',
   '收货地址': '受取住所',
   '交易中心': '取引センター',
   '核销台': '消込台',
   '核销记录': '消込記録',
   '账户中心': '口座センター',
   '账户余额': '口座残高',
   '提现记录': '引き出し記録',
   '我的优惠券': '私のクーポン',
   '我的积分': '私のポイント',
   '账户列表': '口座リスト',
   '基本信息': '基本情報',
   '账号': '口座番号',
   '去绑定': 'バインディング',
   '保存': '保存',
   '邮箱': 'メールアドレス',
   '手机号': '携帯電話番号',
   '昵称': 'ニックネーム',
   '头像照片': 'アバター写真',
   '头像预览': 'アバタープレビュー',
   '头像最佳默认尺寸为120x120像素。': 'アバター最適デフォルトサイズ120 x 120ピクセル',
   '完善个人信息资料，上传头像图片有助于您结识更多的朋友。': '個人情報資料を完備し、顔写真をアップロードすることは、より多くの友达と知り合いになるのに役立ちます。',
   '登录密码': 'ログインパスワード',
   '互联网账号存在被盗风险，建议您定期更改密码以保护账户安全': 'インターネットアカウントが盗まれるリスクがあります。定期的にパスワードを変更してアカウントの安全を保護することをお勧めします',
   '邮箱绑定': 'メールアドレスバインド',
   '验证后，可用于快速找回登录密码及支付密码': '検証後、ログインパスワードと支払いパスワードを素早く取り戻すことができる',
   '手机验证': '携帯電話検証',
   '验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒': '検証後、ログインパスワードと支払いパスワードを素早く取り戻すことができ、口座残高の変動注意',
   '原密码': '元パスワード',
   '当前密码': '現在のパスワードを受け取ることができる',
   '取消': 'キャンセル',
   '新密码': '新パスワード',
   '确认密码': 'パスワードを確認',
   '请确认新密码': '新パスワードを確認',
   '验证码': '認証コード',
   '请输入验证码': '認証コードを入力',
   '动态码': 'ダイナミックコード',
   '请输入动态码': 'ダイナミックコードを入力',
   '点击“获取动态码”，将会向您已绑定的手机号': 'ダイナミックコードを入力',
   '发送验证码': 'ダイナミックコードを取得をクリックすると、バインドされた携帯電話番号',
   '绑定邮箱': 'に認証コードを送信',
   '当前邮箱': 'メールボックス',
   '请输入邮箱': '現在のメールボックス',
   '绑定手机号': 'メールボックス',
   '当前手机号': 'メールボックス',
   '请输入手机号': '携帯電話番号を入力',
   '绑定支付密码': '携帯電話番号をバインド',
   '请输入6位支付密码，建议不要使用重复或连续数字': '支払いパスワードをバインド',
   '验证码已发送至': '6桁の支払いパスワードを入力してください。',
   '收货地址': 'デフォルト',
   '添加收货地址': '編集',
   '默认': 'デフォルト',
   '编辑': '編集',
   '姓名': '名前',
   '是': 'はい',
   '否': 'いいえ',
   '收货人姓名': '受取人の名前',
   '收货人手机号': '受取人の携帯番号',
   '手机': '携帯電話',
   '详细地址': '詳細アドレス',
   '是否默认': 'デフォルト',
   '定位到小区、街道、写字楼': '団地、街、オフィスビルに位置する',
   '手机号不能为空': '携帯番号は空にできません',
   '请输入正确的手机号': '正しい携帯番号を入力してください',
   '请选择区/县': 'エリア/県を選択してください',
   '请选择市': '市を選択してください',
   '请选择省': '省を選択してください',
   '请输入收货人姓名': '受取人の名前を入力してください',
   '宝贝': 'ベイビー',
   '上一页': '前のページ',
   '下一页': '次のページ',
   '您还没有关注店铺哦！': 'まだお店に注目していませんよ!',
   '商品描述': '商品の説明',
   '卖家服务': '売り手サービス',
   '发货速度': '出荷速度',
   '联系电话': '連絡先電話',
   '自营': '自営する',
   '收货人固定电话（选填）': '受取人固定電話（オプション）',
   '取消关注成功': '成功に注目しない',
   '您还没有关注商品哦': 'まだ商品に注目していませんよ',
   '我的足迹': '私の足跡',
   '暂时没有足迹~': 'しばらく足跡がない~',
   '全部订单': 'すべての注文',
   '商品信息': '商品情報',
   '单价': '単価',
   '服务': 'サービス',
   '数量': '数量',
   '实付款': '実払い',
   '订单状态': 'オーダーステータス',
   '操作': '操作',
   '订单号': 'オーダー番号',
   '订单详情': 'オーダー詳細',
   '详细信息': '詳細',
   '转账确认中': '振込確認中',
   '评价': '評価',
   '追评': '追評する',
   '转账详情': '振込詳細',
   '暂无相关订单': '関連オーダーはありません',
   '转账图片': '振り替え画像',
   '提示：如果需要更改转账截图，您可以直接选择支付-转账支付，从新上传支付凭证。': 'ヒント:振り替えのスクリーンショットを変更する必要がある場合は、直接支払い-振り替え支払いを選択し、新しい支払い証明書をアップロードできます。',
   '退款': '払い戻す',
   '退货': '返品',
   '金额': '金額',
   '退款类型': '払戻タイプ',
   '退款无需退货': '返品不要返金',
   '退货退款': '返品返金',
   '退款金额': '返金金額',
   '退款原因': '返金理由',
   '请选择': '選択してください',
   '退款说明': '返金の説明',
   '请输入退款说明（选填）': '返金の説明を入力してください（オプション）',
   '提交': '送信',
   '退款状态': '返金ステータス',
   '退款编号': '払戻番号',
   '退款成功': '返金に成功しました',
   '退款详情': '返金の詳細',
   '退款中': '返金中',
   '提示': 'ヒント',
   '确认撤销': '取り消しの確認',
   '暂不撤销': '取り消さない',
   '撤销成功': '取り消し成功',
   '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请': '取り消し後、今回の申請は閉鎖されます。その後も問題があれば、再び申請を開始できます。',
   '关注商品': '商品に注目',
   '联系客服': 'カスタマーサービス',
   '限时折扣': '時限割引',
   '销售价': '販売価格',
   '原价': '原価',
   '分': '分',
   '市场价': '市場価格',
   '取消关注': '関心を取り消す',
   '关注店铺': '店舗に注目',
   '店铺评分': '店舗採点',
   '进店逛逛': '店に入ってぶらぶらする',
   '累计评价': '累計評価',
   '累计销量': '累計販売',
   '视频': 'ビデオ',
   '图片': '画像',
   '运费': '運賃',
   '快递免邮': '速達無料',
   '快递不免邮': '速達はどうしても郵送しない',
   '折': '折りたたむ',
   '满减': 'ぜんげん',
   '满': '満',
   '减': '減らす',
   '配送至': '配送先',
   '请选择配送地址': '配送先をお選びください',
   '由': 'から',
   '发货并提供售后服务': '出荷とアフターサービス',
   '库存': '在庫品',
   '由官方旗舰店发货并提供售后服务': '公式旗艦店より出荷し、アフターサービスを提供しております',
   '库存不足': '在庫不足',
   '立即购买': '今すぐ購入',
   '加入购物车': 'カートに入れる',
   '手机购买': '携帯購入',
   '组合套餐价': 'セット料金',
   '商品详情': '商品の詳細',
   '商品属性': '商品の属性',
   '商品评价': '商品評価',
   '修改': '変更 ',
   '全部评价': 'すべての評価',
   '追加评价': '追加評価',
   '该商品暂无评价哦': 'この商品は評価されていませんよ',
   '售后保障': 'アフターサービス保障',
   '商品精选': '商品の選り抜き',
   '团购进行中': '団体購入進行中',
   '团购价': '団体購入価格.',
   '暂无正在进行团购的商品，去首页看看吧': '団体購入している商品はしばらくないので、トップページに行ってみましょう',
   '团购': '団体購入',
   '天': 'ああ',
   '小时': '時間',
   '分钟': '分',
   '秒': '秒',
   '起购': '購入する',
   '设为默认': 'デフォルト設定',
   '编辑收货地址': '出荷先住所の編集',
   '电话': '電話番号',
   '地址': 'アドレス',
   '请选择省': '省を選択してください',
   '请选择市': '、市を選択してください',
   '立即抢购': '今すぐ買い占める',
   '商家服务': 'ビジネスサービス',
   '保证服务': '保証サービス',
   '7天退换': '7日間の交換',
   '正品保障': '正品保障',
   '两小时发货': '2時間で出荷',
   '退货承诺': '返品承諾',
   '实体验证': 'エンティティ検証',
   '试用中心': 'トライアルセンター',
   '消协保证': '消協保証',
   '暂无更多品牌,去首页看看吧': 'しばらくブランドがないので、トップページに行ってみましょう。',
   '没有找到您想要的商品。换个条件试试吧': 'ご希望の商品が見つかりませんでした。条件を変えてみよう',
   '搜索结果': '検索結果',
   '综合': '統合',
   '销量': '売上高',
   '价格': '価格',
   '包邮': '郵送する',
   '最低价格': '最低価格',
   '最高价格': '最高価格',
   '确定': 'を選択します。',
   '敬请期待': 'お楽しみに',
   '即将开始': 'まもなく始まる',
   '抢购中': '買い占め中',
   '已结束': '終了',
   '秒杀价': '秒単位で値切る',
   '暂无正在进行秒杀的商品，去首页看看吧': 'ストップウォッチを行っている商品はありませんので、トップページをご覧ください',
   '暂无商品分类': '商品の分類はしばらくありません',
   '优惠券中心': 'クーポンセンター',
   '省钱更多，领券更多': 'お金をもっと節約して、切符をもっともらう。',
   '限时抢券': '期限付きで切符を奪う',
   '叠加使用': '重ね合わせの使用',
   '种类多样': '種類が多様である.',
   '店铺优惠券': '店舗クーポン',
   '使用店铺': '店舗を使う',
   '无门槛优惠券': '敷居なしクーポン',
   '可使用': '使用可能',
   '暂无优惠券': 'クーポンはしばらくありません',
   '去使用': '使用しない',
   '立即领取': 'すぐ受け取る',
   '有效期至': '有効期間:至',
   '领取之日起': '受け取る日から',
   '日内有效': '日内有効',
   '购物车': 'ショッピングカート',
   '账号登录': 'アカウント登録',
   '请输入账号': 'アカウントを入力してください',
   '请输入登录密码': 'ログインパスワードを入力してください',
   '忘记密码': 'パスワードを忘れる',
   '登录': 'ログイン',
   '没有账号': 'アカウントがありません',
   '立即注册': '今すぐ登録',
   '手机动态码登录': '携帯電話の動的コード登録',
   '请输入短信动态码': 'メールダイナミックコードを入力してください',
   '七天自动登录': '7日間自動ログイン',
   '用户注册': 'ユーザー登録',
   '请输入用户名': 'ユーザー名を入力してください',
   '请输入密码': 'パスワードを入力してください',
   '阅读并同意': '読んで同意する',
   '服务协议': 'サービス契約',
   '注册': '登録',
   '已有账号': '既存のアカウント',
   '立即登录': '今すぐログイン',
   '手机动态码注册': '携帯電話の動的コード登録',
   '已有账号，立即登录': 'アカウントがあります。すぐにログインします。',
   '订单列表': 'オーダーリスト12',
   '转账支付': '振込支払い',
   '需付款': '支払いが必要です',
   '订单信息': 'オーダー情報',
   '订单类型': 'オーダー・タイプ',
   '订单编号': 'オーダー番号',
   '订单交易号': '受注取引番号',
   '配送方式': '配送方法',
   '创建时间': '作成時間',
   '支付方式': '支払い方法',
   '支付时间': '支払い時間',
   '店铺活动': '店舗活動',
   '买家留言': 'バイヤーメッセージ',
   '收货人': '受取人',
   '手机号码': '携帯番号',
   '邮编': '郵便番号',
   '发票信息': '請求書情報',
   '发票类型': '請求書タイプ',
   '发票抬头类型': '請求書ヘッダー・タイプ',
   '发票抬头': '請求書ヘッダー',
   '发票内容': '請求書の内容',
   '发票邮寄地址地址': '請求書送付先住所',
   '纸质发票': '紙の領収書',
   '电子发票': '電子請求書',
   '个人': '個人',
   '企业': '企業',
   '发票接收邮箱': '請求書受信メールボックス',
   '小计': '小計',
   '查看退款': '返金の表示',
   '商品金额': '商品金額',
   '税费': '税金',
   '订单调整': 'オーダーの調整',
   '优惠': '特恵',
   '优惠券金额': 'クーポン金額',
   '平台优惠券金额': 'プラットフォームクーポン金額',
   '使用余额': '残高の使用',
   '实付款': '実払い',
   '关闭时间': 'クローズ時間',
   '您确定要关闭该订单吗？': '本当にこの注文をクローズしますか?',
   '订单关闭成功': 'オーダークローズ成功',
   '您确定已经收到货物了吗？': '本当に荷物を受け取りましたか?',
   '订单收货成功': '受注成功',
   '限时秒杀': '時限を定めて秒殺する.',
   '该商品已下架': 'この商品はすでに棚卸しになっている',
   '您的订单已提交成功，正在等待处理！': 'ご注文は正常に提出されました。処理を待っています。',
   '应付金额': '支払金額',
   '交易单号': '取引書番号',
   '订单内容': '注文内容',
   '订单金额': 'オーダー金額',
   '商家未配置支付方式': '事業者が支払方法を設定していない',
   '确认支付': '支払いの確認',
   '立即支付': '即時支払',
   '银行开户名': '銀行口座名',
   '银行账号': '銀行口座',
   '开户支行': '口座を開設して銀行を支える.',
   '我的可用余额': '使用可能残高',
   '提现': '現金を引き出す',
   '充值': 'チャージ',
   '可提现余额': '現金引当可能残高',
   '不可提现余额': '現金引当不可残高',
   '来源': 'ソース',
   '详细说明': '詳細な説明',
   '时间': '時間',
   '描述相符': '説明が一致する',
   '服务态度': 'サービス態度',
   '搜索 商品': '商品を検索する',
   '本店推荐': '当店のおすすめ',
   '欢迎您': 'ようこそ',
   '商城资讯': 'ショッピングモール情報',
   '更多': 'その他',
   '账户类型': 'アカウントのタイプ',
   '提现金额': '現金引き出し金額',
   '提现时间': '現金引き出し時間',
   '提现状态': '提示ステータス',
   '详情': '詳細',
   '暂无提现记录': '現時点での記録なし',
   '提现详情': '詳細を提示する.',
   '当前状态': '現在のステータス',
   '交易号': '取引番号',
   '手续费': '手数料',
   '申请时间': '申請時間',
   '审核时间': 'レビュー時間',
   '银行名称': '銀行名',
   '收款账号': '入金口座',
   '拒绝理由': '理由を断る',
   '转账方式名称': '振込先名',
   '转账时间': '振込時間',
   '平台优惠券': 'プラットフォームクーポン',
   '已过期': '期限切れ',
   '未使用': '未使用',
   '已使用': '使用済み',
   '可用': '使用可能',
   '中文': '中国語',
   '英文': '英語',
   '越语': 'ベトナム語',
   '日语': '日本語',
   '首页': 'トップページ',
   '帮助': 'ヘルプ',
   '帮助列表': 'ヘルプリスト',
   '帮助详情': 'ヘルプの詳細',
   '公告列表': '公告リスト',
   '公告详情': '詳細のお知らせ',
   '最新公告': '最新のお知らせ',
   '商城公告': 'ショッピングモールの公告',
   '登录': 'ログイン',
   '失效商品': '失効商品',
   '件': '件',
   '失效': '効力を失う',
   '全选': 'すべて選択',
   '清除失效宝贝': '失効した宝物を取り除く',
   '已选商品': '選択された商品',
   '合计（不含运费）': '合計（送料込み）',
   '结算': '決済',
   '确定要删除该地址吗？': '本当にこのアドレスを削除しますか?',
   '更多收货地址': 'より多くの出荷先住所',
   '税收': '税金',
   '请先添加收货地址': '先に出荷先住所を追加してください',
   '商家未配置配送方式': '業者が配送方式を設定していない',
   '需要发票': '請求書が必要です',
   '无需发票': '請求書不要',
   '请设置发票信息': '請求書情報を設定してください',
   '设置': '設定',
   '已选择优惠券，已优惠': 'クーポンが選択されており、割引されています',
   '不使用优惠券': 'クーポンはご利用いただけません',
   '订单结算': 'オーダー決済',
   '选择优惠券': 'クーポンの選択',
   '名称': '名前',
   '使用': '使用',
   '期限': '期間',
   '确认选择': '選択の確認',
   '选择平台优惠券': 'プラットフォームクーポンの選択',
   '任意金额可用': '任意の金額で使用可能',
   '选择门店': 'セレクトショップ',
   '营业时间': '営業時間',
   '送达时间': '送達時間',
   '选择时间': '時間の選択',
   '关闭': '閉じる',
   '满额包邮': '満額郵便',
   '留言前建议先与商家协调一致': '伝言の前に業者と協調することをお勧めします',
   '添加发票信息': '請求書情報の追加',
   '请填写抬头名称': 'お名前をお書きください',
   '请填写纳税人识别号': '納税者識別番号を記入してください',
   '请填写发票邮寄地址': '領収書の郵送先をご記入ください',
   '请填写接收邮箱': '受信メールボックスにご記入ください',
   '地区': '地域',
   '邮编（字母或数字）': '郵便番号(アルファベットまたは数字)',
   '定位小区、街道、写字楼': 'ロケーション団地、街、オフィスビル',
   '请输入您的手机号码': '携帯番号を入力してください',
   '购买虚拟类商品需填写您的手机号，以方便商家与您联系': '仮想商品を購入するには、お客様の携帯番号を記入する必要があります。',
   '是否使用余额': '残高を使用するかどうか',
   '不使用余额': '残高を使用しない',
   '为了您的账户安全,请您先设置的支付密码': 'アカウントのセキュリティのために、先に設定した支払いパスワードを教えてください。',
   '可到"会员中心","账号安全","支付密码"中设置': '「会員センター」、「アカウントセキュリティ」、「支払いパスワード」に設定できます',
   '暂不设置': '設定を保留',
   '立即设置': '今すぐ設定',
   '件起,享团购价': '件から団体購入価格を享受する ',
   '发票': '請求書',
   '领券中心': 'クーポンセンター',
   '商城服务': 'ショッピングモールサービス',
   '招商入驻': '企業誘致と駐在',
   '商家中心': '商家センター',
   '门店管理': '店舗管理',
   '更多商品': 'より多くの商品',
   '鲜花电商认证龙头': '生花電子商取引認証蛇口',
   '16年品牌': '16年ブランド',
   '3小时全国送花': '3時間で全国に花を贈る',
   '200%退赔承诺': '200%賠償保証の承諾',
   '最近472658条评价': '最近の472658件の評価',
   '专区': 'エリア',
   '已售': '販売済み',
   '热门资讯': '人気情報',
   '关于我们': 'Về chúng ta',
   '联系方式': '連絡先',
   '友链': '友鎖',
   '收藏网站': 'コレクションサイト',
   '关注微信': '微信に注目する',
   '下载APP': 'APPのダウンロード',
   '订单查询': '受注照会',
   '客户服务': 'カスタマーサービス'


}
