import Vue from "vue"
import VueRouter from "vue-router"
import Lang from "../utils/lang.js"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import indexRoutes from "./modules/index"
import memberRoutes from "./modules/member"
import shopRoutes from "./modules/shop"
import authRoutes from "./modules/auth"
import { getToken } from "../utils/auth"
import store from "../store"
import Config from "../utils/config.js"

// 屏蔽跳转到本身时的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.location.open(location, "_self")

    return originalPush.call(this, location).catch(err => err)
}

const originalResolve = VueRouter.prototype.resolve
VueRouter.prototype.pushToTab = function pushToTab(location) {
	if(!location) return;
    if (location && typeof location == "string" && location.indexOf("http") != -1) return window.open(location, "_blank")

    const { href } = originalResolve.call(this, location)
    window.open(href, "_blank")
}

Vue.use(VueRouter)

/**
 * meta参数解析
 * module: 所属模块，目前用于寻找语言包
 * module: 菜单所属模块
 * menu: 所属菜单，用于判断三级菜单是否显示高亮，如菜单列表、添加菜单、编辑菜单都是'menu'，用户列表、添加用户、编辑用户都是'user'，如此类推
 */
const mainRouters = [
    indexRoutes,
    authRoutes,
    memberRoutes,
    shopRoutes,
    {
        path: "/closed",
        name: "closed",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/closed.vue")
    },
    {
        path: "*",
        name: "error",
        meta: {
            module: "index"
        },
        component: () => import("@/views/index/error.vue")
    }
]

const router = new VueRouter({
	mode:'history',
	base : '/',
    routes: mainRouters
})

// 路由守卫，控制访问权限
router.beforeEach((to, from, next) => {
    if (store.getters.siteInfo && !store.getters.siteInfo.web_status && to.path != "/closed") {
        return next("/closed")
    }
	var wap=0;
	var ua = navigator.userAgent;ua = ua ? ua.toLowerCase().replace(/-/g, "") : "";
	 if (ua.match(/(Android|iPhone|iPod)/i)){wap=1;}
	 if (ua.match(/(U;)/i)){
	    if (ua.match(/(Adr|iPh|iPd)/i)){
	     wap=1;
		}
	 }//UC Browser
	if(wap==1){
	var h5_url=Config.wapDomain;
	var module1=to.meta.module;
	var name=to.name;
	if(module1=='index' || name=='brand'){
	  location.href =h5_url;    	
	}
	if(name=='coupon'){
	 location.href =h5_url+'/otherpages/goods/coupon/coupon'; 	
	}
	if(name=='groupbuy'){
	 location.href =h5_url+'/promotionpages/groupbuy/list/list';	
	}
	if(name=='groupbuy_detail'){
	  var group_id=to.params.pathMatch; 
	  location.href =h5_url+'/promotionpages/groupbuy/detail/detail?groupbuy_id='+group_id;	
	}	
	if(name=='seckill'){
	 location.href =h5_url+'/promotionpages/seckill/list/list';	
	}
	if(name=='seckill_detail'){
	  var skill_id=to.params.pathMatch; 
	  location.href =h5_url+'/promotionpages/seckill/detail/detail?seckill_id='+skill_id; 	
	}
	if(name=='detail' && module1=='goods'){
	   var sid=to.params.pathMatch;
	   location.href =h5_url+'/pages/goods/detail/detail?sku_id='+sid;	
	}
	if(name=='category' && module1=='goods'){
	   location.href =h5_url+'/pages/goods/category/category';	
	}
	if(name=='list' && module1=='goods'){
		var sid=to.query.category_id;
	   location.href =h5_url+'/pages/goods/list/list?category_id='+sid;	
	}		
	if(module1=='notice'){
	   location.href =h5_url+'/otherpages/notice/list/list';	
	}
	if(module1=='help'){
	   location.href =h5_url+'/otherpages/help/list/list';	
	}
	if(module1=='order' && name=='order_list'){
	  location.href =h5_url+'/pages/order/list/list';	
	 }
	 if(name=='footprint'){
	   location.href =h5_url+'/otherpages/member/footprint/footprint';	
	 }
	 if(name=='collection'){
	   location.href =h5_url+'/otherpages/member/collection/collection';	
	  }	 
	 if(name=='delivery_address'){
	   location.href =h5_url+'/otherpages/member/address/address';	
	  }	 
	 if(name=='address_edit'){
		var addid=to.query.id;
		if(addid==undefined){
	      location.href =h5_url+'/otherpages/member/address_edit/address_edit';
		}else{
		 location.href =h5_url+'/otherpages/member/address_edit/address_edit?id='+addid;	
		}
	  }		 
	 if(name=='member'){
	   location.href =h5_url+'/pages/member/index/index';	
	  }		
	 if(name=='info'){
	   location.href =h5_url+'/pages/member/info/info';	
	  }	
	if(name=='security'){
	  location.href =h5_url+'/pages/member/info/info';	
	}
	if(name=='activist'){
	 location.href =h5_url+'/pages/order/activist/activist';	
	}
	if(name=='account'){
	 location.href =h5_url+'/otherpages/member/balance/balance';	
	}	   
	if(name=='my_coupon'){
	location.href =h5_url+'/otherpages/member/coupon/coupon';	
	}	   
	if(name=='my_point'){
	 location.href =h5_url+'/otherpages/member/point/point';	
	}	   
	if(name=='order_detail'){
	  var order_id=to.query.order_id;
	 location.href =h5_url+'/pages/order/detail/detail?order_id='+order_id;	
	}
	if(name=='cart'){
	 location.href =h5_url+'/pages/goods/cart/cart';	
	}
	if(name=='payment'){
	 location.href =h5_url+'/pages/order/payment/payment';	
	}
	if(name=='notice'){
	  location.href =h5_url+'/pages/order/payment/payment';	
	}
	if(name=='login'){	
	 location.href =h5_url+'/pages/login/login/login';	
	}
	if(name=='register'){	
	  location.href =h5_url+'/pages/login/register/register';	
	}	
	
	
	}else{
    if (to.meta.auth) {
        const token = getToken()
        if (!token) {
            return next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
        }
    }

    window.document.body.style.backgroundColor = to.meta.backgroundColor || ""

    NProgress.start()
    next()
   }
})

router.afterEach((to, from) => {
    const title = Lang.getLangField("title", to)
    const metaTitle = store.getters.siteInfo.title || "零点科技"
    window.document.title = `${title} - ${metaTitle}`
    setTimeout(() => {
        if (document.getElementsByClassName("el-main").length) {
            if (to.meta.mainCss) {
                for (let field in to.meta.mainCss) {
                    document.getElementsByClassName("el-main")[0].style[field] = to.meta.mainCss[field]
                }
            } else {
                document.getElementsByClassName("el-main")[0].style = ""
            }
        }
    }, 100)
    NProgress.done()
})

export default router
