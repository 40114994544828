export const lang = {
	//公共
	common: {
		//语言包名称
		name: '英文',
	},

	'所有商品分类': 'All product categories',
	'全国': 'National',
	'切换城市': 'switch cities',
	'您好，请登录 注册': 'Hello, please log in register',
	'退出': 'exit',
	'免费注册': 'free registration',
	'我的订单': 'my order',
	'我的足迹': 'my footprint',
	'我的关注': 'my attention',
	'帮助中心': 'help center',
	'公告': 'announcement',
	'手机商城': 'mobile mall',
	'商品': 'products',
	'店铺': 'store',
	'搜索': 'Search',
	'热门搜索': 'popular search',
	'我的购物车': 'my shopping cart',
	'最新加入的商品': 'newly added products',
	'删除': 'delete',
	'共': 'total',
	'种商品，总金额': 'products, total amount',
	'去购物车': 'go to shopping cart',
	'您的购物车是空的，赶快去逛逛，挑选商品吧！': 'your shopping cart is empty, go shopping and choose products quickly',
	'您买的东西太少了，这里都空空的，快去挑选合适的商品吧！': 'You have bought too few things. It is empty here. Go and choose the right goods',
	'成长值': 'Growth value',
	'未登录': 'not logged in',
	'优惠券': 'coupons',
	'积分': 'points',
	'余额': 'balance',
	'店铺关注': 'store attention',
	'商品关注': 'commodity attention',
	'待付款': 'pending payment',
	'待发货': 'pending delivery',
	'待收货': 'pending receipt',
	'待评价': 'pending evaluation',
	'退款/售后': 'refund / after sales',
	'查看详情': 'view details',
	'会员中心': 'member center',
	'欢迎页': 'welcome page',
	'个人信息': 'personal information',
	'账户安全': 'account security',
	'收货地址': 'receiving address',
	'交易中心': 'trading center',
	'核销台': 'write off desk',
	'核销记录': 'write off record',
	'账户中心': 'account center',
	'账户余额': 'account balance',
	'提现记录': 'withdrawal record',
	'我的优惠券': 'my coupons',
	'我的积分': 'my points',
	'账户列表': 'account list',
	'基本信息': 'basic information',
	'账号': 'account number',
	'去绑定': 'bind',
	'保存': 'save',
	'邮箱': 'email',
	'手机号': 'mobile phone number',
	'昵称': 'nickname',
	'头像照片': 'avatar photo',
	'头像预览': 'avatar Preview The best default size of viewing',
	'头像最佳默认尺寸为120x120像素。': 'avatar is 120x120 pixels',
	'完善个人信息资料，上传头像图片有助于您结识更多的朋友。': 'Improve personal information and upload avatar pictures to help you get to know',
	'登录密码': 'login password',
	'互联网账号存在被盗风险，建议您定期更改密码以保护账户安全': 'internet account is at risk of theft. It is recommended that you change your password regularly to protect account security',
	'修改': 'modify',
	'邮箱绑定': 'email binding',
	'验证后，可用于快速找回登录密码及支付密码': 'after verification, it can be used to quickly retrieve login password and payment password',
	'手机验证': 'mobile phone verification',
	'验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒': 'after verification, it can be used to quickly retrieve login password and payment password, Receive account balance change reminder',
	'原密码': 'original password',
	'当前密码': 'current password',
	'取消': 'cancel',
	'新密码': 'new password',
	'确认密码': 'confirm password',
	'请确认新密码': 'please confirm new password',
	'验证码': 'verification code',
	'请输入验证码': 'Please enter verification code',
	'动态码': 'dynamic code',
	'请输入动态码': 'Please enter dynamic code',
	'点击“获取动态码”，将会向您已绑定的手机号': 'lease enter 6-digit payment password, It is recommended not to use repeated or consecutive numbers',
	'发送验证码': 'send verification code',
	'绑定邮箱': 'bind email',
	'当前邮箱': 'current email',
	'请输入邮箱': 'Please enter email',
	'绑定手机号': 'bind mobile phone number',
	'当前手机号': 'current mobile phone number',
	'请输入手机号': 'Please enter mobile phone number',
	'绑定支付密码': 'bind payment password',
	'请输入6位支付密码，建议不要使用重复或连续数字': 'Please enter 6-digit payment password. It is recommended not to use duplicate or continuous numbers',
	'验证码已发送至': 'verification code has been sent to',
	'收货地址': 'Receiving address',
	'添加收货地址': 'add receiving address',
	'默认': 'default',
	'编辑': 'edit',
	'设为默认': 'set as default',
	'编辑收货地址': 'edit receiving address',
	'姓名': 'name',
	'是': 'Yes',
	'否': 'No',
	'收货人姓名': 'consignee name',
	'收货人手机号': 'consignee mobile phone number',
	'手机': 'mobile phone',
	'地址': 'address',
	'请选择省': 'Please select province',
	'请选择市': 'Please select city',
	'详细地址': 'detailed address',
	'是否默认': 'default',
	'手机号不能为空': 'mobile phone number cannot be empty',
	'请输入正确的手机号': 'Please enter correct mobile phone number',
	'请选择区/县': 'Please select district / county',
	'请输入收货人姓名': 'Please enter consignee name',
	'宝贝': 'baby',
	'上一页': 'previous page',
	'下一页': 'next page',
	'您还没有关注店铺哦！': 'you have not paid attention to the store yet',
	'商品描述': 'Product description',
	'卖家服务': 'seller service',
	'发货速度': 'delivery speed',
	'联系电话': 'contact number',
	'自营': 'self support',
	'取消关注成功': 'cancellation success',
	'您还没有关注商品哦': 'you have not paid attention to the product yet',
	'我的足迹': 'my footprint',
	'暂时没有足迹~': 'temporarily no footprint ~',
	'全部订单': 'all orders',
	'商品信息': 'product information',
	'单价': 'unit price',
	'服务': 'service',
	'数量': 'quantity',
	'实付款': 'actual payment',
	'订单状态': 'order status',
	'操作': 'operation',
	'订单号': 'order number',
	'订单详情': 'order details',
	'详细信息': 'details',
	'转账确认中': 'transfer confirmation',
	'评价': 'evaluation',
	'追评': 'follow-up',
	'转账详情': 'transfer details',
	'暂无相关订单': 'no relevant order',
	'转账图片': 'transfer picture',
	'退款': 'refund',
	'退货': 'return',
	'金额': 'amount',
	'退款类型': 'refund type',
	'退款无需退货': 'refund no return',
	'请选择': 'Please select',
	'退款说明': 'refund Ming',
	'提交': 'submit',
	'退款状态': 'refund status',
	'退款编号': 'refund No.',
	'退款成功': 'refund successful',
	'退款详情': 'refund details',
	'提示': 'Tips',
	'确认撤销': 'confirm cancellation',
	'暂不撤销': 'temporarily not cancel',
	'撤销成功': 'cancellation successful',
	'关注商品': 'concern product',
	'联系客服': 'CS',
	'限时折扣': 'limited time discount',
	'销售价': 'sales price',
	'原价': 'original price',
	'分': 'points',
	'市场价': 'market price',
	'取消关注': 'cancel attention',
	'关注店铺': 'pay attention to stores',
	'店铺评分': 'store score',
	'进店逛逛': 'visit stores',
	'累计评价': 'evaluation',
	'累计销量': 'sales',
	'视频': 'video',
	'图片': 'picture',
	'运费': 'freight',
	'快递免邮': 'express mail free',
	'折': 'discount',
	'满减': 'full reduction',
	'配送至': 'deliver to',
	'由': 'ship by',
	'发货并提供售后服务': 'and provide after-sales service',
	'库存': 'inventory',
	'由官方旗舰店发货并提供售后服务': 'ship by official flagship store and provide after-sales service',
	'库存不足': 'Insufficient inventory',
	'立即购买': 'buy now',
	'加入购物车': 'add shopping cart',
	'手机购买': 'mobile phone purchase',
	'组合套餐价': 'combined package price',
	'商品详情': 'product details',
	'商品属性': 'product attributes',
	'商品评价': 'product evaluation',
	'全部评价': 'all evaluation',
	'追加评价': 'additional evaluation',
	'该商品暂无评价哦': 'no evaluation for this product at present',
	'售后保障': 'after sales guarantee',
	'商品精选': 'product selection',
	'团购进行中': 'group purchase in progress',
	'团购价': 'group price',
	'团购': 'group purchase',
	'天': 'days',
	'小时': 'H',
	'分钟': 'min',
	'秒': 'sec',
	'起购': 'start purchase',
	'立即抢购': 'immediate rush purchase',
	'商家服务': 'merchant service',
	'保证服务': 'guarantee service',
	'7天退换': '7-day return',
	'正品保障': 'genuine product guarantee',
	'两小时发货': 'two-hour delivery',
	'退货承诺': 'return commitment',
	'实体验证': 'physical verification',
	'试用中心': 'trial center',
	'消协保证': 'Consumer Association guarantee',
	'暂无更多品牌,去首页看看吧': 'no more brands at present, Go to the home page and have a look',
	'没有找到您想要的商品。换个条件试试吧': 'I can not find the product you want. Try another condition',
	'搜索结果': 'search results',
	'综合': 'comprehensive',
	'销量': 'sales',
	'价格': 'price',
	'包邮': 'package',
	'最低价格': 'lowest price',
	'最高价格': 'highest price',
	'确定': 'OK',
	'敬请期待': 'please look forward to',
	'即将开始': 'upcoming',
	'抢购中': 'rush purchase',
	'已结束': 'finished',
	'秒杀价': 'price',
	'暂无正在进行秒杀的商品，去首页看看吧': 'No goods in second kill at present, go to the home page',
	'暂无商品分类': 'no product classification',
	'优惠券中心': 'coupon center',
	'省钱更多，领券更多': 'save more money, Get more coupons',
	'限时抢券': 'grab coupons within a limited time',
	'叠加使用': 'overlay use',
	'种类多样': 'various types',
	'店铺优惠券': 'shop coupons',
	'使用店铺': 'use shops',
	'无门槛优惠券': 'no threshold coupons',
	'可使用': 'you can use',
	'暂无优惠券': 'no coupons',
	'去使用': 'go to use',
	'立即领取': 'get them now',
	'有效期至': 'valid until',
	'领取之日起': 'the date of collection',
	'购物车': 'Cart',
	'账号登录': 'account login',
	'请输入账号': 'Please enter the account',
	'请输入登录密码': 'Please enter the login password',
	'忘记密码': 'forget the password',
	'登录': 'Login',
	'没有账号': 'no account',
	'立即注册': 'register immediately',
	'手机动态码登录': 'mobile dynamic code login',
	'请输入短信动态码': 'Please enter the SMS dynamic code',
	'七天自动登录': 'seven day automatic login',
	'用户注册': 'user registration',
	'请输入用户名': 'Please enter the user name',
	'请输入密码': 'Please enter the password',
	'阅读并同意': 'Read and agree to',
	'服务协议': 'service agreement',
	'注册': 'registration',
	'已有账号': 'existing account',
	'立即登录': 'log in immediately',
	'手机动态码注册': 'mobile dynamic code registration',
	'订单列表': 'order list',
	'转账支付': 'transfer payment',
	'需付款': 'payment required',
	'订单信息': 'order information',
	'订单类型': 'order type',
	'订单编号': 'order number',
	'订单交易号': 'order transaction number',
	'配送方式': 'delivery method',
	'创建时间': 'creation time',
	'支付方式': 'payment method',
	'支付时间': 'payment time',
	'店铺活动': 'shop activity',
	'买家留言': 'buyer message',
	'收货人': 'consignee',
	'手机号码': 'mobile number',
	'邮编': 'postal code',
	'发票信息': 'invoice information',
	'发票类型': 'invoice type',
	'发票抬头类型': 'invoice header type',
	'发票抬头': 'invoice header',
	'发票内容': 'invoice content',
	'发票邮寄地址地址': 'invoice mailing address',
	'纸质发票': 'paper invoice',
	'电子发票': 'electronic Invoice',
	'个人': 'individual',
	'企业': 'enterprise',
	'发票接收邮箱': 'invoice receiving email',
	'小计': 'subtotal',
	'查看退款': 'view refund',
	'商品金额': 'commodity amount',
	'税费': 'tax',
	'订单调整': 'order adjustment',
	'优惠': 'discount',
	'优惠券金额': 'coupon amount',
	'平台优惠券金额': 'platform coupon amount',
	'使用余额': 'usage balance',
	'关闭时间': 'closing time',
	'您确定要关闭该订单吗？': 'are you sure you want to close this order',
	'订单关闭成功': 'Order closed successfully',
	'您确定已经收到货物了吗？': 'are you sure you have received the goods',
	'订单收货成功': 'Order received successfully',
	'限时秒杀': 'limited time spike',
	'该商品已下架': 'the product has been taken off the shelf',
	'您的订单已提交成功，正在等待处理！': 'your order has been submitted successfully and is waiting to be processed',
	'应付金额': 'Payable amount',
	'交易单号': 'transaction No.',
	'订单内容': 'order content',
	'订单金额': 'order amount',
	'商家未配置支付方式': 'merchant does not configure payment method',
	'确认支付': 'confirm payment',
	'立即支付': 'pay immediately',
	'银行开户名': 'bank account name',
	'银行账号': 'bank account number',
	'开户支行': 'account opening branch',
	'我的可用余额': 'my available balance',
	'提现': 'withdrawal',
	'充值': 'recharge',
	'可提现余额': 'withdrawable balance',
	'不可提现余额': 'non withdrawable balance',
	'来源': 'source',
	'详细说明': 'detailed description',
	'时间': 'time',
	'描述相符': 'description match',
	'服务态度': 'service attitude',
	'搜索 商品': 'search products',
	'本店推荐': 'our recommendation',
	'欢迎您': 'welcome',
	'商城资讯': 'mall information',
	'更多': 'more',
	'账户类型': 'account type',
	'提现金额': 'withdrawal amount',
	'提现时间': 'withdrawal time',
	'提现状态': 'withdrawal status',
	'详情': 'details',
	'暂无提现记录': 'no withdrawal at present Record',
	'提现详情': 'withdrawal details',
	'当前状态': 'current status',
	'交易号': 'transaction No.',
	'手续费': 'handling charge',
	'申请时间': 'application time',
	'审核时间': 'review time',
	'银行名称': 'bank name',
	'收款账号': 'collection account number',
	'拒绝理由': 'rejection reason',
	'转账方式名称': 'transfer method name',
	'转账时间': 'transfer time',
	'平台优惠券': 'platform coupon',
	'已过期': 'expired',
	'未使用': 'unused',
	'已使用': 'used',
	'可用': 'Available',
	'中文': 'Chinese',
	'英文': 'English',
	'越语': 'Vietnamese',
	'日语': 'Japanese',
	'首页': 'home page',
	'帮助': 'help',
	'帮助列表': 'help list',
	'帮助详情': 'help details',
	'公告列表': 'announcement list',
	'公告详情': 'announcement details',
	'最新公告': 'latest announcement',
	'商城公告': 'mall announcement',
	'失效商品': 'invalid commodities',
	'件': 'pieces',
	'失效': 'invalid',
	'全选': 'select all',
	'清除失效宝贝': 'clear invalid baby',
	'已选商品': 'selected commodities',
	'合计（不含运费）': 'total (excluding freight)',
	'结算': 'settlement',
	'定位到小区、街道、写字楼': 'Locate to community, street and office building',
	'提示：如果需要更改转账截图，您可以直接选择支付-转账支付，从新上传支付凭证。': 'tip: if you need to change the transfer screenshot, you can directly select payment transfer payment and upload the payment voucher again',
	'撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请': 'After cancellation, this application will be closed. If there are still problems in the follow-up, you can apply again',
	'暂无正在进行团购的商品，去首页看看吧': 'there are no goods under group purchase at present, go to the home page',
	'已有账号，立即登录': 'have an account number and log in',
	'电话': 'phone',
	'请输入退款说明（选填）': 'Please enter the refund description (optional)',
	'收货人固定电话（选填）': 'consignee is fixed phone (optional)',
	'退货退款': 'return refund',
	'退款金额': 'refund amount',
	'退款原因': 'refund reason',
	'退款中': 'Refund in progress',
	'快递不免邮': 'express delivery is not free of mail',
	'请选择配送地址': 'Please select the delivery address',
	'满': 'full',
	'减': 'reduce',
	'日内有效': 'Valid within days',
	'确定要删除该地址吗？': 'Are you sure you want to delete this address 12?',
	'更多收货地址': 'More shipping addresses',
	'税收': 'tax revenue',
	'请先添加收货地址': 'Please add the receiving address first',
	'商家未配置配送方式': 'The merchant has not configured the delivery method',
	'需要发票': 'Invoice required',
	'无需发票': 'No invoice required',
	'请设置发票信息': 'Please set invoice information',
	'设置': 'set up',
	'已选择优惠券，已优惠': 'Coupons selected, offers',
	'不使用优惠券': 'Do not use coupons',
	'订单结算': 'Order settlement',
	'选择优惠券': 'Select coupons',
	'名称': 'name',
	'使用': 'use',
	'期限': 'term',
	'确认选择': 'Confirm selection',
	'选择平台优惠券': 'Select platform coupons',
	'任意金额可用': 'Any amount available',
	'选择门店': 'Select store',
	'营业时间': 'Business Hours',
	'送达时间': 'Time of delivery',
	'选择时间': 'Select time',
	'关闭': 'close',
	'满额包邮': 'Full package',
	'留言前建议先与商家协调一致': 'It is recommended to coordinate with the merchant before leaving a message',
	'添加发票信息': 'Add invoice information',
	'请填写抬头名称': 'Please fill in the title',
	'请填写纳税人识别号': 'Please fill in the taxpayer identification number',
	'请填写发票邮寄地址': 'Please fill in the invoice mailing address',
	'请填写接收邮箱': 'Please fill in the receiving email',
	'地区': 'region',
	'邮编（字母或数字）': 'Zip code (letter or number)',
	'定位小区、街道、写字楼': 'Locate community, street and office building',
	'请输入您的手机号码': 'Please enter your mobile phone number',
	'购买虚拟类商品需填写您的手机号，以方便商家与您联系': 'When purchasing virtual goods, you need to fill in your mobile phone number to facilitate the merchant to contact you',
	'是否使用余额': 'Use balance',
	'不使用余额': 'Unused balance',
	'为了您的账户安全,请您先设置的支付密码': 'For your account security, please set your payment password first',
	'可到"会员中心","账号安全","支付密码"中设置': 'You can set it in "member center", "account security" and "payment password"',
	'暂不设置': 'Not set temporarily',
	'立即设置': 'Set now',
	'件起,享团购价': 'Enjoy the group purchase price from pieces',
	'发票': 'invoice',
	'领券中心': 'Coupon collection center',
	'商城服务': 'Mall services',
	'招商入驻': 'Investment invitation and settlement',
	'商家中心': 'Merchant Center',
	'门店管理': 'Store management',
	'更多商品': 'More products',
	'鲜花电商认证龙头': 'Flower leader',
	'16年品牌': '16 years brand',
	'3小时全国送花': '3-hour delivery',
	'200%退赔承诺': '200% refund',
	'最近': 'Recent',
	'条评价':'comments',
	'专区': 'Special area',
	'已售': 'Sold',
	'件': 'pieces',
	'热门资讯': 'Hot news',
	'关于我们': 'About us',
	'联系方式': 'Contact info',
	'友链': 'Friend chain',
	'收藏网站': 'Favorite websites',
	'关注微信': 'Follow wechat',
	'下载APP': 'Download app',
	'订单查询': 'Order query',
	'客户服务': 'CS',
	'联系我们':'Contact us',
	'全国订购热线':'Hotline',
	'免长途费':'Toll free',
	'小时在线订购':'Hour online order',
	'客服工作时间':'Customer service hours',

	'扫码下载手机版':'Download mobile version',
	'关注公众号有惊喜':'Follow the official account',
	'您还未登录':"You haven't signed in yet",
	'定位': 'location',
	'楼号-门牌号': 'Buildingnumber-housenumber',
	'点击获取当前位置': 'GetLocation'
}
