<template>
    <div>
        <div class="header-top">
            <div class="top-content" v-if="pc_layout">
                <div class="tops-left">
                    <div @click="addFavorite">
                        <img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/01/1654080338838284.png" alt="">
                        {{ $lang('收藏网站') }}
                    </div>
                    <div @mouseover="onEnterTd" @mouseout="onEnters">
                        <span> <img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/01/1654080381944209.png"
                                alt=""> {{ $lang('关注微信') }}</span>
                        <div v-if="flows">
                            <!-- <img :src="$img(siteInfo.web_qrcode)" alt=""> -->
                            <span>{{ siteInfo.web_weixin }}</span>
                        </div>
                    </div>
                    <div @mouseover="onEntersApp" @mouseout="onEnterApp">
                        <span>{{ $lang('下载APP') }}</span>
                        <div v-if="Appflows">
                            <img :src="$img(siteInfo.web_qrcode)" alt="">
                        </div>
                    </div>
                </div>
                <div class="tops-right">
                    <router-link v-if="!logined" to="/login" class="ns-text-color">{{ $lang('您好，请登录 注册') }}
                    </router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">{{ $lang('退出') }}</span>
                    </div>
                    <router-link to="/member/order_list">{{ $lang('订单查询') }}</router-link>
                    <router-link to="/member/index">{{ $lang('客户服务') }}</router-link>
                    <router-link to="/cart">
                        <span>{{ $lang('购物车') }}</span>
                        <em v-show="cartCount">{{ cartCount }}</em>
                    </router-link>
                    <router-link to="/index">{{ $trans(siteInfo.title) }}</router-link>
                    <el-dropdown @command="handleLanguse" trigger="click">
                        <span class="el-dropdown-link">
                            {{ locale_name }}
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh">{{ $lang('中文') }}</el-dropdown-item>
                            <el-dropdown-item command="en">{{ $lang('英文') }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="top-content" v-if="!pc_layout">
                <div class="top-left" v-if="addonIsExit.city">
                    <span>
                        <i class="el-icon-location"></i>
                        <template v-if="city && city.title">
                            {{ city.title }}
                        </template>
                        <template v-else>
                            {{ $lang('全国') }}
                        </template>
                    </span>
                    <el-tag size="mini" effect="plain" type="info" class="change-city"
                        @click="$router.push('/change_city')">{{ $lang('切换城市') }}</el-tag>
                </div>
                <div class="top-right">
                    <router-link v-if="!logined" to="/login" class="ns-text-color">{{ $lang('您好，请登录') }}</router-link>
                    <div v-if="logined" class="member-info">
                        <router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
                        <span @click="logout">{{ $lang('退出') }}</span>
                    </div>
                    <router-link v-if="!logined" to="/register">{{ $lang('免费注册') }}</router-link>
                    <router-link to="/member/order_list">{{ $lang('我的订单') }}</router-link>
                    <router-link to="/member/footprint">{{ $lang('我的足迹') }}</router-link>
                    <router-link to="/member/collection">{{ $lang('我的关注') }}</router-link>

                    <router-link to="/cms/help">{{ $lang('帮助中心') }}</router-link>
                    <router-link to="/cms/notice">{{ $lang('公告') }}</router-link>
                    <el-dropdown v-if="qrcode1">
                        <span class="el-dropdown-link">
                            {{ $lang('手机商城') }}
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="mobile-qrcode"><img :src="$img(qrcode)" /></div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>

                    <el-dropdown @command="handleLanguse" trigger="click">
                        <span class="el-dropdown-link">
                            {{ locale_name }}
                            <i class="el-icon-arrow-down"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="zh">{{ $lang('中文') }}</el-dropdown-item>
                            <el-dropdown-item command="en">{{ $lang('英文') }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getToken } from "@/utils/auth"

export default {
    props: {},
    data() {
        return {
            locale_name: "中文",
            local: "zh",
            qrcode1: !1,
            flows: false,
            pc_layout: false,
            Appflows: false
        }
    },
    created() {
        if (this.siteInfo.pc_layout == '2') {
            this.pc_layout = true

        } else {
            this.pc_layout = false

        }
        this.$store.dispatch("site/qrCodes")
        this.$store.dispatch("member/member_detail")
        this.$store.dispatch("site/defaultFiles")
        this.$store.dispatch("site/addons")
    },
    mounted() {
        this.local = this.$store.state.app.lang;
    },
    watch: {
        local() {
            if (this.local == 'zh') { this.locale_name = this.$lang('中文'); }
            if (this.local == 'en') { this.locale_name = this.$lang('英文'); }
        }
    },
    methods: {
        logout() {
            this.$store.dispatch("member/logout")
        },
        handleLanguse(command) {
            this.$store.dispatch('app/lang', command);
            this.local = command;
            //this.$router.go(0);
        },
        //鼠标进入公众号的事件。
        onEnterTd() {
            this.flows = true

        },
        onEnters() {
            this.flows = false
        },
        //鼠标进入App的事件。
        onEntersApp() {
            this.Appflows = true

        },
        onEnterApp() {
            this.Appflows = false
        },
        // 收藏当前网站
        addFavorite() {
            var url = window.location;
            var title = document.title;
            var ua = navigator.userAgent.toLowerCase();
            // 符合加入收藏
            if (ua.indexOf("360se") > -1) {
                alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
            }
            else if (ua.indexOf("msie 8") > -1) {
                window.external.AddToFavoritesBar(url, title); //IE8
            }
            else if (document.all) {
                try {
                    window.external.addFavorite(url, title);
                } catch (e) {
                    alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
                }
            }
            else if (window.sidebar) {
                window.sidebar.addPanel(title, url, "");
            }
            else {
                alert('您的浏览器不支持,请按 Ctrl+D 手动收藏!');
            }
        }
    },
    components: {},
    computed: {
        ...mapGetters(["wapQrcode", "member", "addonIsExit", "city", 'siteInfo', 'cartCount']),
        qrcode: function () {
            return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
        },
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    }
}
</script>

<style scoped lang="scss">
.header-top {
    width: 100%;
    height: 31px;
    font-size: 12px;
    background-color: #f5f5f5;

    .el-dropdown {
        font-size: $ns-font-size-sm;
    }

    .top-content {
        width: $width;
        height: 100%;
        margin: 0 auto;

        .top-left {
            height: 100%;
            float: left;
            height: 100%;
            line-height: 31px;

            .change-city {
                cursor: pointer;
                margin-left: 5px;

                &:hover {
                    color: $base-color;
                    border-color: $base-color;
                }
            }
        }

        .top-right {
            height: 100%;
            float: right;
            font-size: $ns-font-size-sm;
            color: $ns-text-color-black;

            a {
                float: left;
                line-height: 31px;
                padding: 0 10px;

                &:hover {
                    color: $base-color;
                }
            }

            div {
                float: left;
                height: 100%;
                margin-left: 10px;
                cursor: pointer;
                line-height: 31px;
                padding: 0 5px;

                &.member-info {
                    margin-left: 0;

                    span:first-child {
                        margin-right: 10px;
                    }
                }

                span:hover {
                    color: $base-color;
                }

                &.el-dropdown:hover {
                    background-color: #fff;
                }
            }
        }

        .tops-left {
            height: 100%;
            float: left;
            line-height: 31px;
            display: flex;

            a {
                padding: 0 10px;
                color: #999999;

                &:hover {
                    color: $base-color;
                }
            }

            div {
                &:nth-child(1) {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 0 10px;
                    color: #999999;

                    img {
                        padding: 0 5px;
                    }

                    &:hover {
                        color: $base-color;
                    }
                }
				&:nth-child(2){
					div{
						position: absolute;
						z-index: 9;
						background: #f5f5f5;
					}
				}
                div {
                   

                    img {
                        width: 100px;
                        position: absolute;
                        z-index: 999;
                        height: 100px;
                    }
                }
            }

            span {
                padding: 0 10px;
                color: #999999;
                cursor: pointer;

                &:hover {
                    color: $base-color;
                }
            }
        }

        .tops-right {
            height: 100%;
            float: right;
            line-height: 31px;
            font-size: $ns-font-size-sm;
            color: $ns-text-color-black;
            display: flex;
            align-items: center;

            a {
                color: #999999;
                padding: 0 15px;

                &:hover {
                    color: $base-color;
                }
            }

            .member-info {
                color: #999999;
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            .el-dropdown {
                cursor: pointer;
            }
        }
    }
}

.mobile-qrcode {
    padding: 10px 0;
}

.router-link-active {
    color: $base-color;
}

.el-dropdown {
    color: #999999;
    padding: 0 15px;
}
</style>
