export const lang = {
	//公共
	common: {
		//语言包名称
		name: '中文',
	},
	'所有商品分类': '所有商品分类',
	'全国': '全国',
	'切换城市': '切换城市',
	'您好，请登录 注册': '您好，请登录 注册',
	'退出': '退出',
	'免费注册': '免费注册',
	'我的订单': '我的订单',
	'我的足迹': '我的足迹',
	'我的关注': '我的关注',
	'帮助中心': '帮助中心',
	'公告': '公告',
	'手机商城': '手机商城',
	'商品': '商品',
	'店铺': '店铺',
	'搜索': '搜索',
	'热门搜索': '热门搜索',
	'我的购物车': '我的购物车',
	'最新加入的商品': '最新加入的商品',
	'删除': '删除',
	'共': '共',
	'种商品，总金额': '种商品，总金额',
	'去购物车': '去购物车',
	'您的购物车是空的，赶快去逛逛，挑选商品吧！': '您的购物车是空的，赶快去逛逛，挑选商品吧！',
	'您买的东西太少了，这里都空空的，快去挑选合适的商品吧！': '您买的东西太少了，这里都空空的，快去挑选合适的商品吧！',
	'成长值': '成长值',
	'未登录': '未登录',
	'优惠券': '优惠券',
	'积分': '积分',
	'余额': '余额',
	'店铺关注': '店铺关注',
	'商品关注': '商品关注',
	'待付款': '待付款',
	'待发货': '待发货',
	'待收货': '待收货',
	'待评价': '待评价',
	'退款/售后': '退款/售后',
	'待付款': '待付款',
	'查看详情': '查看详情',
	'会员中心': '会员中心',
	'欢迎页': '欢迎页',
	'个人信息': '个人信息',
	'账户安全': '账户安全',
	'收货地址': '收货地址',
	'交易中心': '交易中心',
	'核销台': '核销台',
	'核销记录': '核销记录',
	'账户中心': '账户中心',
	'账户余额': '账户余额',
	'提现记录': '提现记录',
	'我的优惠券': '我的优惠券',
	'我的积分': '我的积分',
	'账户列表': '账户列表',
	'基本信息': '基本信息',
	'账号': '账号',
	'去绑定': '去绑定',
	'保存': '保存',
	'邮箱': '邮箱',
	'手机号': '手机号',
	'昵称': '昵称',
	'头像照片': '头像照片',
	'头像预览': '头像预览',
	'头像最佳默认尺寸为120x120像素。': '头像最佳默认尺寸为120x120像素。',
	'完善个人信息资料，上传头像图片有助于您结识更多的朋友。': '完善个人信息资料，上传头像图片有助于您结识',
	'登录密码': '登录密码',
	'互联网账号存在被盗风险，建议您定期更改密码以保护账户安全': '互联网账号存在被盗风险，建议您定期更改密码以保护账户安全',
	'修改': '修改',
	'邮箱绑定': '邮箱绑定',
	'验证后，可用于快速找回登录密码及支付密码': '验证后，可用于快速找回登录密码及支付密码',
	'手机验证': '手机验证',
	'验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒': '验证后，可用于快速找回登录密码及支付密码，接收账户余额变动提醒',
	'原密码': '原密码',
	'当前密码': '当前密码',
	'取消': '取消',
	'新密码': '新密码',
	'确认密码': '确认密码',
	'请确认新密码': '请确认新密码',
	'验证码': '验证码',
	'请输入验证码': '请输入验证码',
	'动态码': '动态码',
	'请输入动态码': '请输入动态码',
	'点击“获取动态码”，将会向您已绑定的手机号': '点击“获取动态码”，将会向您已绑定的手机号',
	'发送验证码': '发送验证码',
	'绑定邮箱': '绑定邮箱',
	'当前邮箱': '当前邮箱',
	'请输入邮箱': '请输入邮箱',
	'绑定手机号': '绑定手机号',
	'当前手机号': '当前手机号',
	'请输入手机号': '请输入手机号',
	'绑定支付密码': '绑定支付密码',
	'请输入6位支付密码，建议不要使用重复或连续数字': '请输入6位支付密码，建议不要使用重复或连续数字',
	'验证码已发送至': '验证码已发送至',
	'添加收货地址': '添加收货地址',
	'默认': '默认',
	'编辑': '编辑',
	'设为默认': '设为默认',
	'编辑收货地址': '编辑收货地址',
	'姓名': '姓名',
	'是': '是',
	'否': '否',
	'收货人姓名': '收货人姓名',
	'收货人手机号': '收货人手机号',
	'手机': '手机',
	'电话': '电话',
	'地址': '地址',
	'请选择省': '请选择省',
	'请选择市': '请选择市',
	'详细地址': '详细地址',
	'是否默认': '是否默认',
	'定位到小区、街道、写字楼': '定位到小区、街道、写字楼',
	'手机号不能为空': '手机号不能为空',
	'请输入正确的手机号': '请输入正确的手机号',
	'请选择区/县': '请选择区/县',
	'请选择市': '请选择市',
	'请选择省': '请选择省',
	'请输入收货人姓名': '请输入收货人姓名',
	'宝贝': '宝贝',
	'上一页': '上一页',
	'下一页': '下一页',
	'您还没有关注店铺哦！': '您还没有关注店铺哦！',
	'商品描述': '商品描述',
	'卖家服务': '卖家服务',
	'发货速度': '发货速度',
	'联系电话': '联系电话',
	'自营': '自营',
	'收货人固定电话（选填）': '收货人固定电话（选填）',
	'取消关注成功': '取消关注成功',
	'您还没有关注商品哦': '您还没有关注商品哦',
	'我的足迹': '我的足迹',
	'暂时没有足迹~': '暂时没有足迹~',
	'全部订单': '全部订单',
	'商品信息': '商品信息',
	'单价': '单价',
	'服务': '服务',
	'数量': '数量',
	'实付款': '实付款',
	'订单状态': '订单状态',
	'操作': '操作',
	'实付款': '实付款',
	'订单号': '订单号',
	'订单详情': '订单详情',
	'详细信息': '详细信息',
	'转账确认中': '转账确认中',
	'评价': '评价',
	'追评': '追评',
	'转账详情': '转账详情',
	'暂无相关订单': '暂无相关订单',
	'转账图片': '转账图片',
	'提示：如果需要更改转账截图，您可以直接选择支付-转账支付，从新上传支付凭证。': '提示：如果需要更改转账截图，您可以直接选择支付-转账支付，从新上传支付凭证。',
	'退款': '退款',
	'退货': '退货',
	'金额': '金额',
	'退款类型': '退款类型',
	'退款无需退货': '退款无需退货',
	'退货退款': '退货退款',
	'退款金额': '退款金额',
	'退款原因': '退款原因',
	'请选择': '请选择',
	'退款说明': '退款说明',
	'请输入退款说明（选填）': '请输入退款说明（选填）',
	'提交': '提交',
	'退款状态': '退款状态',
	'退款编号': '退款编号',
	'退款成功': '退款成功',
	'退款详情': '退款详情',
	'退款中': '退款中',
	'提示': '提示',
	'确认撤销': '确认撤销',
	'暂不撤销': '暂不撤销',
	'撤销成功': '撤销成功',
	'撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请': '撤销之后本次申请将会关闭,如后续仍有问题可再次发起申请',
	'关注商品': '关注商品',
	'联系客服': '联系客服',
	'限时折扣': '限时折扣',
	'销售价': '销售价',
	'原价': '原价',
	'分': '分',
	'市场价': '市场价',
	'取消关注': '取消关注',
	'关注店铺': '关注店铺',
	'店铺评分': '店铺评分',
	'进店逛逛': '进店逛逛',
	'累计评价': '累计评价',
	'累计销量': '累计销量',
	'视频': '视频',
	'图片': '图片',
	'运费': '运费',
	'快递免邮': '快递免邮',
	'快递不免邮': '快递不免邮',
	'折': '折',
	'满减': '满减',
	'满': '满',
	'减': '减',
	'配送至': '配送至',
	'请选择配送地址': '请选择配送地址',
	'由': '由',
	'发货并提供售后服务': '发货并提供售后服务',
	'库存': '库存',
	'由官方旗舰店发货并提供售后服务': '由官方旗舰店发货并提供售后服务',
	'库存不足': '库存不足',
	'立即购买': '立即购买',
	'加入购物车': '加入购物车',
	'手机购买': '手机购买',
	'组合套餐价': '组合套餐价',
	'商品详情': '商品详情',
	'商品属性': '商品属性',
	'商品评价': '商品评价',
	'全部评价': '全部评价',
	'追加评价': '追加评价',
	'该商品暂无评价哦': '该商品暂无评价哦',
	'售后保障': '售后保障',
	'商品精选': '商品精选',
	'团购进行中': '团购进行中1',
	'团购价': '团购价',
	'暂无正在进行团购的商品，去首页看看吧': '暂无正在进行团购的商品，去首页看看吧',
	'团购': '团购',
	'天': '天',
	'小时': '小时',
	'分钟': '分钟1',
	'秒': '秒',
	'起购': '起购',
	'立即抢购': '立即抢购',
	'商家服务': '商家服务',
	'保证服务': '保证服务',
	'7天退换': '7天退换',
	'正品保障': '正品保障',
	'两小时发货': '两小时发货',
	'退货承诺': '退货承诺',
	'实体验证': '实体验证',
	'试用中心': '试用中心',
	'消协保证': '消协保证',
	'暂无更多品牌,去首页看看吧': '暂无更多品牌,去首页看看吧',
	'没有找到您想要的商品。换个条件试试吧': '没有找到您想要的商品。换个条件试试吧',
	'搜索结果': '搜索结果',
	'综合': '综合',
	'销量': '销量',
	'价格': '价格',
	'包邮': '包邮',
	'最低价格': '最低价格',
	'最高价格': '最高价格',
	'确定': '确定',
	'敬请期待': '敬请期待',
	'即将开始': '即将开始',
	'抢购中': '抢购中',
	'已结束': '已结束',
	'秒杀价': '秒杀价',
	'暂无正在进行秒杀的商品，去首页看看吧': '暂无正在进行秒杀的商品，去首页看看吧',
	'暂无商品分类': '暂无商品分类',
	'优惠券中心': '优惠券中心',
	'省钱更多，领券更多': '省钱更多，领券更多',
	'限时抢券': '限时抢券',
	'叠加使用': '叠加使用',
	'种类多样': '种类多样',
	'店铺优惠券': '店铺优惠券',
	'使用店铺': '使用店铺',
	'无门槛优惠券': '无门槛优惠券',
	'可使用': '可使用',
	'暂无优惠券': '暂无优惠券',
	'去使用': '去使用',
	'立即领取': '立即领取',
	'有效期至': '有效期至',
	'领取之日起': '领取之日起',
	'日内有效': '日内有效',
	'购物车': '购物车',
	'账号登录': '账号登录',
	'请输入账号': '请输入账号',
	'请输入登录密码': '请输入登录密码',
	'忘记密码': '忘记密码',
	'登录': '登录',
	'没有账号': '没有账号',
	'立即注册': '立即注册',
	'手机动态码登录': '手机动态码登录',
	'请输入短信动态码': '请输入短信动态码',
	'七天自动登录': '七天自动登录',
	'用户注册': '用户注册',
	'请输入用户名': '请输入用户名',
	'请输入密码': '请输入密码',
	'阅读并同意': '阅读并同意',
	'服务协议': '服务协议',
	'注册': '注册',
	'已有账号': '已有账号',
	'立即登录': '立即登录',
	'手机动态码注册': '手机动态码注册',
	'已有账号，立即登录': '已有账号，立即登录',
	'订单列表': '订单列表12',
	'转账支付': '转账支付',
	'需付款': '需付款',
	'订单信息': '订单信息',
	'订单类型': '订单类型',
	'订单编号': '订单编号',
	'订单交易号': '订单交易号',
	'配送方式': '配送方式',
	'创建时间': '创建时间',
	'支付方式': '支付方式',
	'支付时间': '支付时间',
	'店铺活动': '店铺活动',
	'买家留言': '买家留言',
	'收货人': '收货人',
	'手机号码': '手机号码',
	'邮编': '邮编',
	'发票信息': '发票信息',
	'发票类型': '发票类型',
	'发票抬头类型': '发票抬头类型',
	'发票抬头': '发票抬头',
	'发票内容': '发票内容',
	'发票邮寄地址地址': '发票邮寄地址地址',
	'纸质发票': '纸质发票',
	'电子发票': '电子发票',
	'个人': '个人',
	'企业': '企业',
	'发票接收邮箱': '发票接收邮箱',
	'小计': '小计',
	'查看退款': '查看退款',
	'商品金额': '商品金额',
	'税费': '税费',
	'订单调整': '订单调整',
	'优惠': '优惠',
	'优惠券金额': '优惠券金额',
	'平台优惠券金额': '平台优惠券金额',
	'使用余额': '使用余额',
	'实付款': '实付款',
	'关闭时间': '关闭时间',
	'您确定要关闭该订单吗？': '您确定要关闭该订单吗？',
	'订单关闭成功': '订单关闭成功',
	'您确定已经收到货物了吗？': '您确定已经收到货物了吗？',
	'订单收货成功': '订单收货成功',
	'限时秒杀': '限时秒杀',
	'该商品已下架': '该商品已下架',
	'您的订单已提交成功，正在等待处理！': '您的订单已提交成功，正在等待处理22！',
	'应付金额': '应付金额',
	'交易单号': '交易单号',
	'订单内容': '订单内容',
	'订单金额': '订单金额',
	'商家未配置支付方式': '商家未配置支付方式',
	'确认支付': '确认支付',
	'立即支付': '立即支付',
	'银行开户名': '银行开户名',
	'银行账号': '银行账号',
	'开户支行': '开户支行',
	'立即支付': '立即支付',
	'我的可用余额': '我的可用余额',
	'提现': '提现',
	'充值': '充值',
	'可提现余额': '可提现余额',
	'不可提现余额': '不可提现余额',
	'来源': '来源',
	'详细说明': '详细说明',
	'时间': '时间',
	'描述相符': '描述相符',
	'服务态度': '服务态度',
	'搜索 商品': '搜索 商品',
	'本店推荐': '本店推荐',
	'欢迎您': '欢迎您',
	'商城资讯': '商城资讯',
	'更多': '更多',
	'账户类型': '账户类型',
	'提现金额': '提现金额',
	'提现时间': '提现时间',
	'提现状态': '提现状态',
	'详情': '详情',
	'暂无提现记录': '暂无提现记录',
	'提现详情': '提现详情',
	'当前状态': '当前状态',
	'交易号': '交易号',
	'手续费': '手续费',
	'申请时间': '申请时间',
	'审核时间': '审核时间',
	'银行名称': '银行名称',
	'收款账号': '收款账号',
	'拒绝理由': '拒绝理由',
	'转账方式名称': '转账方式名称',
	'转账时间': '转账时间',
	'平台优惠券': '平台优惠券',
	'已过期': '已过期',
	'未使用': '未使用',
	'已使用': '已使用',
	'可用': '可用',
	'中文': '中文',
	'英文': '英文',
	'越语': '越语',
	'日语': '日语',
	'首页': '首页',
	'帮助': '帮助',
	'帮助列表': '帮助列表',
	'帮助详情': '帮助详情',
	'公告列表': '公告列表',
	'公告详情': '公告详情',
	'最新公告': '最新公告',
	'商城公告': '商城公告',
	'登录': '登录',
	'失效商品': '失效商品',
	'件': '件',
	'失效': '失效',
	'全选': '全选',
	'清除失效宝贝': '清除失效宝贝',
	'已选商品': '已选商品',
	'合计（不含运费）': '合计（不含运费）',
	'结算': '结算',
	'确定要删除该地址吗？': '确定要删除该地址吗12？',
	'更多收货地址': '更多收货地址',
	'税收': '税收',
	'请先添加收货地址': '请先添加收货地址',
	'商家未配置配送方式': '商家未配置配送方式',
	'需要发票': '需要发票',
	'无需发票': '无需发票',
	'商家未配置配送方式': '商家未配置配送方式',
	'请设置发票信息': '请设置发票信息',
	'设置': '设置',
	'已选择优惠券，已优惠': '已选择优惠券，已优惠',
	'不使用优惠券': '不使用优惠券',
	'订单结算': '订单结算',
	'选择优惠券': '选择优惠券',
	'名称': '名称',
	'使用': '使用',
	'期限': '期限',
	'名称': '名称',
	'确认选择': '确认选择',
	'选择平台优惠券': '选择平台优惠券',
	'任意金额可用': '任意金额可用',
	'选择门店': '选择门店',
	'营业时间': '营业时间',
	'送达时间': '送达时间',
	'选择时间': '选择时间',
	'关闭': '关闭',
	'满额包邮': '满额包邮',
	'留言前建议先与商家协调一致': '留言前建议先与商家协调一致',
	'添加发票信息': '添加发票信息',
	'请填写抬头名称': '请填写抬头名称',
	'请填写纳税人识别号': '请填写纳税人识别号',
	'请填写发票邮寄地址': '请填写发票邮寄地址',
	'请填写接收邮箱': '请填写接收邮箱',
	'地区': '地区',
	'邮编（字母或数字）': '邮编（字母或数字）',
	'定位小区、街道、写字楼': '定位小区、街道、写字楼',
	'请输入您的手机号码': '请输入您的手机号码',
	'购买虚拟类商品需填写您的手机号，以方便商家与您联系': '购买虚拟类商品需填写您的手机号，以方便商家与您联系',
	'是否使用余额': '是否使用余额',
	'不使用余额': '不使用余额',
	'为了您的账户安全,请您先设置的支付密码': '为了您的账户安全,请您先设置的支付密码',
	'可到"会员中心","账号安全","支付密码"中设置': '可到"会员中心","账号安全","支付密码"中设置',
	'暂不设置': '暂不设置',
	'立即设置': '立即设置',
	'件起,享团购价': '件起,享团购价',
	'发票': '发票',
	'领券中心': '领券中心',
	'商城服务': '商城服务',
	'招商入驻': '招商入驻',
	'商家中心': '商家中心',
	'门店管理': '门店管理',
	'更多商品': '更多商品',
	'鲜花电商认证龙头': '鲜花电商认证龙头',
	'16年品牌': '16年品牌',
	'3小时全国送花': '3小时全国送花',
	'200%退赔承诺': '200%退赔承诺',
	'最近': '最近',
	'条评价': '条评价',
	'专区': '专区',
	'已售': '已售',
	'件': '件',
	'热门资讯': '热门资讯',
	'关于我们': '关于我们',
	'联系方式': '联系方式',
	'友链': '友链',
	'收藏网站': '收藏网站',
	'关注微信': '关注微信',
	'下载APP': '下载APP',
	'订单查询': '订单查询',
	'客户服务': '客户服务',
	'联系我们': '联系我们',
	'全国订购热线': '全国订购热线',
	'免长途费': '免长途费',
	'小时在线订购': '小时在线订购',
	'客服工作时间': '客服工作时间',
	'扫码下载手机版': '扫码下载手机版',
	'关注公众号有惊喜': '关注公众号有惊喜',
	'您还未登录': '您还未登录',
	'定位': '定位',
	'楼号-门牌号': '楼号-门牌号',
	'点击获取当前位置': '点击获取当前位置'
}
