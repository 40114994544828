<template>
    <div class="footer">
        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0 && !pc_layout">
            <el-tab-pane label="友情链接" name="first">
                <div>
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index"
                        :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img
                                :src="$img(link_item.link_pic)" /></span>
                    </div>

                    <div class="clear"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">友情链接</div>
			<div>
				<div class="clear"></div>
			</div>
		</div> -->
        <div class="footer-top" v-if="shopServiceList.length > 0">
            <ul class="service" v-if="pc_layout">
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/01/1654080686967157.png" alt="">
                    </div>
                    <p> {{ $lang('7天退换') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161275730260.png" alt="">
                    </div>
                    <p>{{ $lang('正品保障') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161288123679.png" alt="">
                    </div>
                    <p>{{ $lang('两小时发货') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161302789404.png" alt="">
                    </div>
                    <p>{{ $lang('退货承诺') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161316288949.png" alt="">
                    </div>
                    <p>{{ $lang('试用中心') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161327546728.png" alt="">
                    </div>
                    <p>{{ $lang('实体验证') }}</p>
                </li>
                <li>
                    <!-- <div><i class="iconfont" :class="item.pc_icon"></i></div> -->
                    <div><img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161344406782.png" alt="">
                    </div>
                    <p>{{ $lang('消协保证') }}</p>
                </li>
            </ul>
            <ul class="service" v-if="!pc_layout">
                <li v-for="(item, index) in shopServiceList" :key="index">
                    <div><i class="iconfont" :class="item.pc_icon"></i></div>
                    <p>{{ $trans(item.name) }}</p>
                </li>
            </ul>
        </div>
        <div class="footer-bot" style="border-bottom: 1px solid #FFFFFF;" v-if="!pc_layout">
            <div class="left" v-if="siteInfo.web_phone || siteInfo.web_email" style="display: none">
                <div>
                    <p class="left-phone" v-if="siteInfo.web_phone">{{ siteInfo.web_phone }}</p>
                    <p class="left-email">{{ siteInfo.web_email }}</p>
                </div>
            </div>
        </div>
        <div class="footer-contact" v-if="pc_layout">
            <div class="contact-flex">
                <div class="contact-service">
                    <p>{{ $lang('联系我们') }}</p>
                    <p>{{ $lang('全国订购热线') }} : {{ $store.state.site.siteInfo.web_phone }}({{ $lang('免长途费') }})</p>
                    <p>E-mail: {{ $store.state.site.siteInfo.web_email }}</p>
                    <p>7x24{{ $lang('小时在线订购') }}</p>
                    <p>{{ $lang('客服工作时间') }}: {{ $store.state.site.siteInfo.work_time }} </p>
                    <div @click="service_link">
                        <img src="https://sai-kuang.topcompete.com/shufa/img/2022/05/31/1653979988305863.png" alt="">
                        <span data-collects="0">{{ $lang('联系客服') }}</span>
                    </div>
                </div>
                <div class="contact-code">
                    <div>
                        <img :src="$img($store.state.site.siteInfo.app_qrcode)" alt="">
                        <span>{{ $lang('扫码下载手机版') }}</span>
                    </div>
                    <div>
                        <img :src="$img($store.state.site.siteInfo.web_qrcode)" alt="">
                        <span>{{ $lang('关注公众号有惊喜') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footers-bot" style="border-bottom: 1px solid #FFFFFF;" v-if="pc_layout">
            <div class="bot-router">
                <router-link to="/index">{{ $lang('首页') }}</router-link>
                <span>|</span>

                <div @click="$router.pushToTab({ path: '/cms/notic' })">
                    {{ $lang('关于我们') }}
                </div>
                <span>|</span>
                <div @mouseover="onEnterTd" @mouseout="onEnters">
                    {{ $lang('联系方式') }}
                    <div v-if="flows">
                        <span>
                            {{ siteInfo.web_phone }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="bot-txt">
                <p> <span>{{ $trans(copyright.company_name) }}</span>
                    <router-link :to="{ path: copyright.copyright_link }">{{ $trans(copyright.copyright_desc) }}
                    </router-link>
                    <router-link :to="{ path: copyright.gov_url }">{{ $trans(copyright.gov_record) }}</router-link>
                    <router-link :to="{ path: copyright.gov_url }">{{ $trans(copyright.icp) }}</router-link>
                </p>
                <p>
                    <span>
                        {{ $trans($store.state.site.siteInfo.desc) }}
                    </span>
                </p>
                <div class="botCate">
                    <div v-for="item in linkCate" :key="item.id">
                        <img :src="$img(item.link_pic)" alt="">
                    </div>
                </div>
                <div>
                    {{ $lang('友链') }} ：
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index"
                        :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)">{{ $trans(link_item.link_title)
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <servicerMessage ref="servicerMessage" class="kefu"
            :shop="{ shop_id: 0, logo: siteInfo.logo, shop_name: '平台客服' }"></servicerMessage>
    </div>
</template>

<script>
import { copyRight, shopServiceLists, friendlyLink, friendlyLinks } from "@/api/website"
import CopyRight from "./CopyRight"
import { mapGetters } from "vuex"
import { helpList } from "@/api/cms/help"
import {
    shopServiceOpen
} from "@/api/website.js"
import servicerMessage from "@/components/message/servicerMessage";
export default {
    props: {},
    data() {
        return {
            shopServiceList: [],
            linkList: [],
            helpList: [],
            ishide: false,
            activeName: "first",
            pc_layout: false,
            copyright: {},
            flows: false,
            linkCate: {}
        }
    },
    computed: {
        ...mapGetters(["siteInfo", "token"])
    },
    created() {
        this.copyright = this.siteInfo.copyright.data.value
        if (this.siteInfo.pc_layout == '2') {
            this.pc_layout = true
        } else {
            this.pc_layout = false

        }
        this.getShopServiceLists()
        this.link()
        this.getHelpList()
        console.log('gh', this.shopServiceList)
    },
    mounted() { },
    watch: {},
    methods: {

        //鼠标进入的事件。
        onEnterTd() {
            this.flows = true

        },
        onEnters() {
            this.flows = false

        },
        getShopServiceLists() {
            shopServiceLists({}).then(res => {
                if (res.code == 0 && res.data) {
                    this.shopServiceList = res.data
                }
            })
        },
        link() {
            friendlyLink({})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.linkList = res.data.list
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
            friendlyLinks({ type: 2 })
                .then(res => {
                    console.log(res)
                    this.linkCate = res.data.list
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        linkUrl(url, target) {
            if (!url) return
            if (url.indexOf("http") == -1) {
                if (target) this.$router.pushToTab({ path: url })
                else this.$router.push({ path: url })
            } else {
                if (target) window.open(url)
                else window.location.href = url
            }
        },
        /**
         * 获取帮助列表
         */
        getHelpList() {
            helpList()
                .then(res => {
                    if (res.code == 0 && res.data) {
                        var arr = [];
                        arr = res.data.slice(0, 4)

                        for (let i = 0; i < arr.length; i++) {
                            arr[i].child_list = arr[i].child_list.slice(0, 4);
                        }

                        this.helpList = arr
                    }
                })
                .catch(err => { })
        },
        /**
         * 图片加载失败
         */
        imageError() {
            this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
        },
        /**
         * 跳转到帮助列表
         */
        clickToHelp(id) {
            this.$router.push("/cms/help/listother-" + id)
        },
        /**
         * 跳转到帮助详情
         */
        clickToHelpDetail(id) {
            this.$router.push("/cms/help-" + id)
        },
        // 联系客服
        service_link() {
            if (this.token) {
                shopServiceOpen().then((res) => {
                    if (res.code == 0) {
                        if (res.data.type == 'third') {
                            window.open(res.data.third, "_blank");
                        } else if (res.data.type == 'system') {
                            this.$refs.servicerMessage.show()
                        }
                    }
                })
            } else {
                this.$message({
                    message: "您还未登录",
                    type: "warning"
                })
            }
        }
    },
    components: {
        CopyRight,
        servicerMessage
    }
}
</script>

<style scoped>
.right /deep/ .el-image__inner {
    vertical-align: baseline;
    max-width: 100%;
    max-height: 100%;
}
</style>
<style scoped lang="scss">
.footer {
    .footer-top {
        background-color: #fff;

        .service {
            margin: 0;
            padding: 0;
            width: $width;
            margin: 0 auto;
            padding: 50px 0;
            box-sizing: border-box;
            border-bottom: 1px solid #e9e9e9;
            display: flex;
            justify-content: space-around;
            align-items: center;

            li {
                list-style: none;
                line-height: 50px;
                text-align: center;
                flex: 1;

                div {
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    line-height: 48px;

                    i {
                        font-size: 48px;
                        color: $base-color;
                    }
                }

                p {
                    font-size: 16px;
                    line-height: 20px;
                    margin-top: 10px;
                }
            }
        }
    }

    .footer-center {
        width: $width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 50px 0;

        .left {
            // width: 300px;

            .left-phone {
                font-size: 20px;
                font-weight: 600;
                color: $base-color;
                padding-bottom: 10px;
                margin-bottom: 12px;
            }

            .left-email {
                font-size: 18px;
                color: #838383;
            }
        }

        .center {
            width: calc(100% - 550px);
            padding: 0 68px;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 18px;
                font-weight: 600;
                color: #838383;
                margin-bottom: 10px;
                cursor: pointer;
            }

            .help-li {
                font-size: 14px;
                color: #838383;
                line-height: 30px;
                cursor: pointer;
            }

            p:hover,
            .help-li:hover {
                color: $base-color;
            }
        }

        .right {
            width: 250px;
            text-align: center;

            .el-image {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            p {
                font-size: 18px;
                color: #838383;
            }
        }

        .qrcode-hide {
            display: none;
        }
    }

    .footer-bot {
        background: #242424;
        color: #9D9D9D;
        width: $width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 50px 350px;

        .left {

            // width: 300px;
            .left-phone {
                font-size: 26px;
                font-weight: 600;
                // color: $base-color;
                padding-bottom: 10px;
                // border-bottom: 1px solid $base-color;
                border-bottom: 1px solid #FFFFFF;
                margin-bottom: 12px;
            }

            .left-email {
                font-size: 18px;
                color: #838383;
            }
        }

        .center {
            width: calc(100% - 550px);
            padding: 0 68px;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 18px;
                font-weight: 600;
                color: #838383;
                margin-bottom: 10px;
                cursor: pointer;
            }

            .help-li {
                font-size: 14px;
                color: #838383;
                line-height: 30px;
                cursor: pointer;
            }

            p:hover,
            .help-li:hover {
                color: $base-color;
            }
        }

        .right {
            width: 250px;
            text-align: center;

            .el-image {
                width: 120px;
                height: 120px;
                line-height: 120px;
                text-align: center;
                background-color: #FFFFFF;
            }

            p {
                font-size: 18px;
                color: #838383;
            }
        }

        .qrcode-hide {
            display: none;
        }
    }

    .footer-fot {
        background: #242424;
        color: #9D9D9D;
        width: $width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 0px 350px;
    }

    .friendly-link {
        width: $width;
        margin: 0 auto;
        border: 1px solid #e9e9e9;

        .link-title {
            line-height: 30px;
            padding: 10px 0 5px;
            margin: 0px 0 15px;
            border-bottom: 1px solid #e8e8e8;
        }

        .link-item {
            width: 10%;
            height: 50px;
            line-height: 47px;
            float: left;
            text-align: center;
            overflow: hidden;
            margin: 0 6px 10px 6px;
            -webkit-transition: opacity 0.3s, box-shadow 0.3s;
            transition: opacity 0.3s, box-shadow 0.3s;
            border: 1px solid #fff;
            border-left: none;
            cursor: pointer;
        }

        .link-item:hover {
            width: -webkit-calc(10% + 1px);
            width: calc(10% + 1px);
            margin-left: 4px;
            position: relative;
            opacity: 1;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border: 1px solid #dddddd;
        }

        .clear {
            clear: both;
        }
    }

    .footers-bot {
        background: #242424;
        color: #9D9D9D;
        width: $width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 30px 350px;

        .bot-router {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                cursor: pointer;
            }

            a {
                color: #ffffff !important;
                font-size: 15px
            }

            span {
                color: #FFFFFF;
                padding: 0 25px;
            }

            div {
                position: relative;
                color: #ffffff !important;
                font-size: 15px;

                div {
                    width: 150px;
                    background: #fff;
                    position: absolute;

                    span {
                        color: #000000;
                    }
                }

            }
        }

        .bot-txt {
            margin-top: 15px;

            p {
                &:nth-child(2) {
                    margin-top: 12px;
                }

                span {
                    color: #FFFFFF;
                    opacity: 0.6;
                    font-size: 14px;
                    padding: 2px 10px;
                }

                a {
                    color: #FFFFFF;
                    opacity: 0.6;
                    font-size: 14px;
                    padding: 2px 10px;
                }
            }

            >div {
                margin-top: 12px;
                padding: 2px 10px;
                display: flex;
                align-items: center;

                span {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            .botCate {
                display: flex;
                align-items: center;
                margin-top: 12px;

                img {
                    height: 30px;
                    width: 150px;
                }
            }
        }
    }

    .footer-contact {
        background: #FFFFFF;

        .contact-flex {
            width: 1200px;
            padding: 40px;
            margin: 0 auto;
            justify-content: space-around;
            display: flex;

            .contact-service {
                p {
                    color: #000000;
                    font-size: 14px;

                    &:nth-child(1) {
                        font-weight: 600;
                        font-size: 16px;
                    }
                }

                div {
                    cursor: pointer;
                    width: 110px;
                    height: 35px;
                    background: #A3A4A5;
                    border-radius: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    position: relative;

                    img {
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 10px;
                    }

                    span {
                        color: #FFFFFF;
                        margin-left: 20px;
                    }
                }
            }

            .contact-code {
                display: flex;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:nth-child(1) {
                        margin-right: 45px;
                    }

                    img {
                        width: 120px;
                        height: 120px;
                    }

                    span {
                        color: #000000;
                        font-size: 16px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.friendly-link {
    .el-tabs__nav-scroll {
        padding-left: 30px;
        height: 50px;
        line-height: 50px;
    }

    .el-tabs__content {
        padding: 0 20px;
    }

    .el-tabs__nav-wrap::after {
        height: 1px;
    }

    .el-tabs__nav {
        padding: 0 20px;
    }

    .el-tabs__active-bar {
        padding: 0 20px;
    }
}
</style>
